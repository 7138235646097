import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  TemplateRef,
  ViewEncapsulation,
  contentChildren,
  inject,
  input,
  output,
  viewChild,
} from '@angular/core';
import { CapturumTemplateDirective } from '@capturum/ui/api';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cap-sidebar',
  templateUrl: './sidebar.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SidebarModule, CommonModule],
})
export class CapturumSidebarComponent implements AfterViewInit, OnDestroy {
  public title = input<string>();
  public styleClass = input<string>();
  public position = input<'right' | 'left'>('right');

  public style = input<string>();
  public modal = input<boolean>(true);

  public visibleChange = output<boolean>();
  public sidebarClose = output<boolean>();

  public sidebarComponent = viewChild<Sidebar>('sidebar');
  public templates = contentChildren(CapturumTemplateDirective);

  public subscription: Subscription;
  public footerTemplate: TemplateRef<any>;

  private cdr = inject(ChangeDetectorRef);

  private _visible = false;

  public get visible(): boolean {
    return this._visible;
  }

  @Input()
  public set visible(value: boolean) {
    if (value !== this._visible) {
      this._visible = value;

      if (!value && this.sidebarComponent()) {
        // trigger primeng sidebar close event
        this.sidebarComponent().close(new Event('click'));
      }
    }
  }

  public ngAfterViewInit(): void {
    this.subscription = this.sidebarComponent().visibleChange?.subscribe((visible) => {
      this.visibleChange.emit(visible);
    });

    const footerTemplate = this.templates().find((template) => {
      return template.getType() === 'footer';
    });

    if (footerTemplate) {
      this.footerTemplate = footerTemplate.template;
    }
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public closeSidebar(event?: MouseEvent): void {
    if (event) {
      this.sidebarComponent()?.close(event);
      this.sidebarClose.emit(true);
    }

    this.visible = false;
  }
}
