import { ComponentType } from '@angular/cdk/portal';
import { Component, EventEmitter, Inject, Input, OnDestroy, Optional, Output, ViewContainerRef } from '@angular/core';
import { FilterMatchMode } from '@capturum/ui/api';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilKeyChanged, take, takeUntil } from 'rxjs/operators';
import { dynamicFilterComponents } from '../../dynamic-filter-map';
import {
  ActiveFilters,
  DynamicComponentMap,
  DynamicFilterComponent,
  FilterConfigItem,
} from '../../interfaces/dynamic-filter.interface';
import { DYNAMIC_FILTER_COMPONENTS } from '../../providers/dynamic-filter-components.provider';
import { DynamicFiltersService } from './../../services/dynamic-filters.service';

@Component({
  selector: 'cap-dynamic-filter',
  template: '',
  styleUrls: ['./dynamic-filter.component.scss'],
  standalone: true,
})
export class CapturumDynamicFilterComponent implements OnDestroy {
  @Output()
  public updateValue = new EventEmitter<{ field: string; value: unknown }>();

  private destroy$ = new Subject();
  private updateValueSubject = new BehaviorSubject<any>(null);
  private componentInstance!: DynamicFilterComponent;
  private _dynamicFilterComponents: DynamicComponentMap[];

  constructor(
    public viewContainerRef: ViewContainerRef,
    private dynamicFiltersService: DynamicFiltersService,
    @Optional()
    @Inject(DYNAMIC_FILTER_COMPONENTS)
    private dynamicFilterComponentsMap?: DynamicComponentMap[]
  ) {
    this._dynamicFilterComponents = dynamicFilterComponentsMap || dynamicFilterComponents;
  }

  @Input()
  public set activeFilter(filter: ActiveFilters) {
    this.updateValueSubject.next(filter?.value || null);
  }

  private _filterConfig!: FilterConfigItem;

  public get filterConfig(): FilterConfigItem {
    return this._filterConfig;
  }

  @Input()
  set filterConfig(value: FilterConfigItem) {
    this._filterConfig = value;
    this.loadComponent(value);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private loadComponent(filterConfigItem: FilterConfigItem): void {
    const component = this.findComponentByName(filterConfigItem.type);
    const viewContainerRef = this.viewContainerRef;

    if (filterConfigItem.dependsOn?.length && !filterConfigItem.preventDisableDependency) {
      filterConfigItem.disabled = true;
    }

    viewContainerRef.clear();

    if (component) {
      const componentReference = viewContainerRef.createComponent(component);
      this.componentInstance = componentReference.instance;

      componentReference.instance.filterConfig = filterConfigItem;

      componentReference.instance.updateValue.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        this.handleFilterChange(filterConfigItem, value);
      });

      this.dynamicFiltersService
        .getActiveFilterDependencies(filterConfigItem)
        .pipe(
          distinctUntilKeyChanged('value', (prev, next) => {
            return JSON.stringify(prev) === JSON.stringify(next);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((activeFilter) => {
          const emptyFilterValue =
            activeFilter.value === null || (Array.isArray(activeFilter.value) && !activeFilter.value.length);

          if (!filterConfigItem.preventDisableDependency) {
            filterConfigItem.disabled = emptyFilterValue;

            filterConfigItem.disabled
              ? this.componentInstance.formControl.disable({ emitEvent: false })
              : this.componentInstance.formControl.enable({ emitEvent: false });
            this.componentInstance.updateView();
          }

          componentReference.setInput('filterConfig', filterConfigItem);
          this.componentInstance.filterConfig = filterConfigItem;

          const listOptions = emptyFilterValue
            ? null
            : {
                filters: [
                  {
                    field: activeFilter?.field,
                    value: activeFilter?.value,
                    operator: FilterMatchMode.IN,
                  },
                ],
              };

          if (emptyFilterValue) {
            this.componentInstance.setValue(null);
            this.updateValue.emit({
              field: filterConfigItem.field,
              value: null,
            });
          }

          if (filterConfigItem.updateListOptions) {
            filterConfigItem.options = filterConfigItem.updateListOptions(listOptions);

            // If the options list changes, update current selected values in the multi-select/dropdown
            filterConfigItem.options?.pipe(take(1)).subscribe((newOptions) => {
              const currentSelectedOptions: string[] = this.componentInstance?.formControl?.value;
              const newSelectedValues = currentSelectedOptions?.filter((selectedOption) =>
                newOptions.some((option) => option.value === selectedOption)
              );

              if (newSelectedValues && currentSelectedOptions?.length !== newSelectedValues?.length) {
                this.componentInstance.formControl.setValue(newSelectedValues);
                this.componentInstance.updateValue.next(newSelectedValues);
                this.componentInstance.updateView();
              }
            });
          }
        });

      this.updateValueSubject
        .asObservable()
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          this.dynamicFiltersService.setActiveFilterValueChanged({
            field: filterConfigItem.field,
            value: value,
          });

          this.componentInstance.setValue(value);
        });
    } else {
      throw Error(
        `Filter type ${filterConfigItem.type} not found. Make sure to provide this filter in the forRoot config of the CapturumDynamicFiltersModule`
      );
    }
  }

  private handleFilterChange(filterConfigItem: FilterConfigItem, value: any): void {
    const newFilterValue = filterConfigItem.transformValue ? filterConfigItem.transformValue(value) : value;

    this.updateValue.emit({
      field: filterConfigItem.field,
      value: newFilterValue,
    });
  }

  private findComponentByName(name: string): ComponentType<DynamicFilterComponent> {
    const componentMap = this._dynamicFilterComponents.find((componentMap) => componentMap.name === name);

    return componentMap?.component || null;
  }
}
