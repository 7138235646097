import { Injectable } from '@angular/core';
import { filter, Observable, ReplaySubject } from 'rxjs';
import { ActiveFilters } from '../interfaces/dynamic-filter.interface';
import { FilterConfigItem } from './../interfaces/dynamic-filter.interface';

type ActiveFilter = Omit<ActiveFilters, 'matchMode'>;

@Injectable({
  providedIn: 'root',
})
export class DynamicFiltersService {
  private dynamicFilterActiveFilterChange$ = new ReplaySubject<ActiveFilter>(10);

  public setActiveFilterValueChanged(activeFilter: ActiveFilter): void {
    this.dynamicFilterActiveFilterChange$.next(activeFilter);
  }

  public getActiveFilterDependencies(filterConfig: FilterConfigItem): Observable<ActiveFilter> {
    return this.dynamicFilterActiveFilterChange$.asObservable().pipe(
      filter((activeFilter) => {
        return activeFilter && filterConfig.dependsOn?.includes(activeFilter.field);
      }),
    );
  }
}
