import { AfterViewInit, Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { FormControl, NgControl, NgModel } from '@angular/forms';
import { ValidatorService, ValueAccessorBase } from '@capturum/ui/api';

@Component({
  selector: 'cap-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(CapturumInputSwitchComponent)]
})
export class CapturumInputSwitchComponent extends ValueAccessorBase<boolean> implements AfterViewInit {
  @ViewChild(NgModel, { static: true }) public model: NgModel;
  @Input() public style: any;
  @Input() public styleClass: string;
  @Input() public inputId: string;
  @Input() public name: string;
  @Input() public label: string;
  @Input() public disabled: boolean = undefined;
  @Input() public readonly: boolean;
  @Output() public change: EventEmitter<any> = new EventEmitter();

  public control: FormControl;

  constructor(private validatorService: ValidatorService,
              private injector: Injector) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }
}
