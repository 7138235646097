import { Injectable } from '@angular/core';
import { ListOptions, Meta } from '@capturum/api';
import { FilterMatchMode, TablePaginator } from '@capturum/ui/api';
import { ActiveFilters } from '@capturum/ui/dynamic-filters';
import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

type ListOptionsFilter = {
  field: string;
  value: string;
  operator?: string;
}

@Injectable()
export class TableService {
  private updateTableSubject = new BehaviorSubject<ListOptions>(null);
  private paginatorSubject = new BehaviorSubject<TablePaginator>({
    rows: 20,
    total: 0,
    total_pages: 0,
    first: 1,
    per_page: 20,
    current_page: 1,
  });

  public updateTableByLazyLoadEvent(event?: LazyLoadEvent): void {
    const apiOptions = { ...this.updateTableSubject.getValue() };

    if (event) {
      if (event.sortField) {
        apiOptions.sort = [
          {
            field: event.sortField,
            direction: event.sortOrder === 1 ? 'asc' : 'desc',
          },
        ];
      }

      // reset sort if no sortField is present
      if (!event.sortField && apiOptions.sort?.length) {
        apiOptions.sort = [];
      }

      // reset filters if no filters and no globalFilters are present
      if (this.isObjectEmpty(event.filters) && !event.globalFilter && apiOptions.filters?.length) {
        apiOptions.filters = [];
      }

      if (event.first !== undefined) {
        apiOptions.page = Math.floor(event.first / event.rows) + 1;
      }

      apiOptions.perPage = event.rows;
    }

    this.updateTableSubject.next(apiOptions);
  }

  public updateTableByFilters(filters: ActiveFilters[]): void {
    const optionFilters = filters.reduce<ListOptionsFilter[]>((acc, activeFilter) => {
      let value = activeFilter.value as string;

      if (value !== null) {
        if (activeFilter.matchMode === FilterMatchMode.LIKE) {
          value = `%${value}%`;
        }

        return [
          ...acc,
          {
            field: activeFilter.field,
            value,
            operator: activeFilter.matchMode,
          },
        ];
      } else {
        return acc;
      }
    }, []);

    this.updateTableSubject.next({
      ...this.updateTableSubject.getValue(),
      filters: optionFilters,
    });
  }

  public getUpdateTable(): Observable<ListOptions> {
    return this.updateTableSubject.asObservable();
  }

  public resetUpdateTable(): void {
    return this.updateTableSubject.next(null);
  }

  public refreshTable(): void {
    this.updateTableSubject.next(this.updateTableSubject.getValue());
  }

  public getPaginator(): Observable<TablePaginator> {
    return this.paginatorSubject.asObservable();
  }

  public setPaginator(meta: Meta): void {
    this.paginatorSubject.next({
      rows: meta?.pagination.per_page,
      total: meta?.pagination.total,
      total_pages: meta?.pagination.total_pages,
      first: (meta?.pagination.current_page - 1) * meta?.pagination.per_page,
      per_page: meta?.pagination.per_page,
      current_page: meta?.pagination.current_page,
    });
  }

  private isObjectEmpty(objectName: object): boolean {
    return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
  }
}
