<div class="cap-dynamic-filter-layout">
  @if (label) {
    <label for="">
      {{ label }}
    </label>
  }

  <div class="cap-dynamic-filter-layout-content">
    <ng-content></ng-content>

    <div class="filter-icon">
      <i [class]="iconClass"></i>
    </div>

    @if (showReset) {
      <div class="delete-filter" (click)="resetFilter.emit()">
        <i class="fas fa-trash-alt"></i>
      </div>
    }
  </div>
</div>
