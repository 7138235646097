import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumSidebarModule } from '@capturum/ui/sidebar';
import { CapturumTableModule } from '@capturum/ui/table';
import { TranslateModule } from '@ngx-translate/core';
import { ChipsModule } from 'primeng/chips';
import { SidebarModule } from 'primeng/sidebar';
import { CalendarFilterComponent } from './components/calendar-filter/calendar-filter.component';
import { CapturumDynamicFiltersComponent } from './components/cap-dynamic-filters/dynamic-filters.component';
import { CheckboxFilterComponent } from './components/checkbox-filter/checkbox-filter.component';
import { CapturumDropdownFilterComponent } from './components/dropdown-filter/dropdown-filter.component';
import { CapturumDynamicFilterLayoutComponent } from './components/dynamic-filter-layout/dynamic-filter-layout.component';
import { CapturumDynamicFilterComponent } from './components/dynamic-filter/dynamic-filter.component';
import { DynamicFiltersSidebarComponent } from './components/dynamic-filters-sidebar/dynamic-filters-sidebar.component';
import { InputFilterComponent } from './components/input-filter/input-filter.component';
import { CapturumMultiSelectFilterComponent } from './components/multi-select-filter/multi-select-filter.component';
import { DynamicRangeFilterComponent } from './components/range-filter/range-filter.component';
import { dynamicFilterComponents } from './dynamic-filter-map';
import { DynamicFilterModuleConfig } from './interfaces/dynamic-filter.interface';
import { DateFilterSelectionModePipe } from './pipes/date-filter-selection-mode.pipe';
import { FilterFieldPipe } from './pipes/filter-field.pipe';
import { DYNAMIC_FILTER_COMPONENTS } from './providers/dynamic-filter-components.provider';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CapturumInputModule,
    CapturumButtonModule,
    CapturumSharedModule,
    CapturumDropdownModule,
    CapturumMultiSelectModule,
    CapturumCalendarModule,
    CapturumCheckboxModule,
    CapturumTableModule,
    SidebarModule,
    CapturumSidebarModule,
    DragDropModule,
    ChipsModule,
    TranslateModule,
    FormsModule,
    CapturumInputSwitchModule,
    // Pipes
    DateFilterSelectionModePipe,
    FilterFieldPipe,
    CapturumDynamicFilterLayoutComponent,
    CapturumDynamicFilterComponent,
    // Components
    CapturumDynamicFiltersComponent,
    DynamicFiltersSidebarComponent,
    InputFilterComponent,
    CheckboxFilterComponent,
    CapturumDropdownFilterComponent,
    DynamicRangeFilterComponent,
    CalendarFilterComponent,
    CapturumMultiSelectFilterComponent,
  ],
  exports: [CapturumDynamicFilterComponent, CapturumDynamicFiltersComponent, DynamicRangeFilterComponent],
})
export class CapturumDynamicFiltersModule {
  public static forRoot(config?: DynamicFilterModuleConfig): ModuleWithProviders<CapturumDynamicFiltersModule> {
    return {
      ngModule: CapturumDynamicFiltersModule,
      providers: [
        {
          provide: DYNAMIC_FILTER_COMPONENTS,
          useValue: [...(config?.components || []), ...dynamicFilterComponents],
        },
      ],
    };
  }
}
