import { Observable } from 'rxjs';

export interface CalendarConfig {
  defaultDate?: Observable<Date> | Date;
  firstDayOfWeek?: Observable<number> | number;
  dateFormat?: Observable<string> | string;
  showTime?: Observable<boolean> | boolean;
  showIcon?: Observable<boolean> | boolean;
  timeLabel?: Observable<string> | string;
  style?: Observable<any> | any;
  styleClass?: Observable<string> | string;
  inputStyle?: Observable<any> | any;
  inputStyleClass?: Observable<string> | string;
  placeholder?: Observable<string> | string;
  icon?: Observable<string> | string;
  appendTo?: Observable<any> | any;
  readonlyInput?: Observable<boolean> | boolean;
  shortYearCutoff?: Observable<any> | any;
  monthNavigator?: Observable<boolean> | boolean;
  yearNavigator?: Observable<boolean> | boolean;
  hourFormat?: Observable<string> | string;
  timeOnly?: Observable<boolean> | boolean;
  stepHour?: Observable<number> | number;
  stepMinute?: Observable<number> | number;
  stepSecond?: Observable<number> | number;
  showSeconds?: Observable<boolean> | boolean;
  required?: Observable<boolean> | boolean;
  showOnFocus?: Observable<boolean> | boolean;
  showWeek?: Observable<boolean> | boolean;
  dataType?: Observable<string> | string;
  selectionMode?: Observable<string> | string;
  maxDateCount?: Observable<number> | number;
  showButtonBar?: Observable<boolean> | boolean;
  todayButtonStyleClass?: Observable<string> | string;
  clearButtonStyleClass?: Observable<string> | string;
  autoZIndex?: Observable<boolean> | boolean;
  baseZIndex?: Observable<number> | number;
  panelStyleClass?: Observable<string> | string;
  panelStyle?: Observable<any> | any;
  keepInvalid?: Observable<boolean> | boolean;
  hideOnDateTimeSelect?: Observable<boolean> | boolean;
  hideOnRangeSelect?: Observable<boolean> | boolean;
  numberOfMonths?: Observable<number> | number;
  view?: Observable<string> | string;
  touchUI?: Observable<boolean> | boolean;
  timeSeparator?: Observable<string> | string;
  showTransitionOptions?: Observable<string> | string;
  hideTransitionOptions?: Observable<string> | string;
  disabledDates?: Observable<Date[]> | Date[];
  disabledDays?: Observable<number[]> | number[];
  inline?: Observable<boolean> | boolean;
  maxDate?: Observable<Date> | Date;
  minDate?: Observable<Date> | Date;
  showOtherMonths?: Observable<boolean> | boolean;
  tabindex?: Observable<number> | number;
  yearRange?: Observable<string> | string;
  showApplyButton?: Observable<boolean> | boolean;
  selectOtherMonths?: Observable<boolean> | boolean;
  setTimeZone?: boolean;
  sendFormat?: string;
  sendDateTimeFormat?: string;
  iconDisplay?: Observable<'input' | 'button'> | 'input' | 'button';
}
