import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { TranslationIndexedDbModel } from '../models/translation.indexedDb-model';
import { PrimeNGConfig } from 'primeng/api';
import { TranslationResponse } from '../models/translation-response.model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class TranslationService extends ApiService<any> {
  protected endpoint = 'role/translation';

  constructor(private api: ApiHttpService,
              private translateService: TranslateService,
              private authService: AuthService,
              private config: PrimeNGConfig,
  ) {
    super(api);

    this.translateService.onLangChange.subscribe(({ translations }) => {
      this.setPrimengLocale(translations);
    });
  }

  /**
   * Get the translations for the current user
   *
   * @return Observable<any>
   */
  public getTranslations(): Observable<any> {
    return this.apiHttp.get<{ data: TranslationResponse }>(`/${this.endpoint}`).pipe(
      map((response: { data: any }) => response.data),
      tap(async response => {
        for (const key in response) {
          if (response.hasOwnProperty(key)) {
            TranslationIndexedDbModel.query().put({ id: key, value: response[key] });
          }
        }
      }),
    );
  }

  /**
   * Load translations and set default or current users language
   *
   * @return Promise<void>
   */
  public loadTranslations(): Observable<any> {
    return this.apiHttp.get<{ data: TranslationResponse }>(`/${this.endpoint}`)
      .pipe(
        catchError(async () => {
          await TranslationIndexedDbModel.loadTranslations();

          return TranslationIndexedDbModel.translations;
        }),
        map((response: any) => response.data ? response.data : response),
        this.processTranslationResponse.bind(this),
      );
  }

  public loadFilteredTranslations(groups?: string[], locale?: string): Observable<any> {
    const params = this.getHttpParams(groups, locale);

    return this.api.get<{ data: TranslationResponse }>('/translation/filtered', { params }).pipe(
      map((response) => response.data),
      this.processTranslationResponse.bind(this),
    );
  }

  protected setPrimengLocale(translations: Record<string, string>): void {
    this.config.setTranslation({
      dayNames: [
        translations['calendar-locale.day-names.sunday'],
        translations['calendar-locale.day-names.monday'],
        translations['calendar-locale.day-names.tuesday'],
        translations['calendar-locale.day-names.wednesday'],
        translations['calendar-locale.day-names.thursday'],
        translations['calendar-locale.day-names.friday'],
        translations['calendar-locale.day-names.saturday'],
      ],
      dayNamesShort: [
        translations['calendar-locale.day-names.sun'],
        translations['calendar-locale.day-names.mon'],
        translations['calendar-locale.day-names.tue'],
        translations['calendar-locale.day-names.wed'],
        translations['calendar-locale.day-names.thu'],
        translations['calendar-locale.day-names.fri'],
        translations['calendar-locale.day-names.sat'],
      ],
      dayNamesMin: [
        translations['calendar-locale.day-names.su'],
        translations['calendar-locale.day-names.mo'],
        translations['calendar-locale.day-names.tu'],
        translations['calendar-locale.day-names.we'],
        translations['calendar-locale.day-names.th'],
        translations['calendar-locale.day-names.fr'],
        translations['calendar-locale.day-names.sa'],
      ],
      monthNames: [
        translations['calendar-locale.month-names.january'],
        translations['calendar-locale.month-names.february'],
        translations['calendar-locale.month-names.march'],
        translations['calendar-locale.month-names.april'],
        translations['calendar-locale.month-names.may'],
        translations['calendar-locale.month-names.june'],
        translations['calendar-locale.month-names.july'],
        translations['calendar-locale.month-names.august'],
        translations['calendar-locale.month-names.september'],
        translations['calendar-locale.month-names.october'],
        translations['calendar-locale.month-names.november'],
        translations['calendar-locale.month-names.december'],
      ],
      monthNamesShort: [
        translations['calendar-locale.month-names.jan'],
        translations['calendar-locale.month-names.feb'],
        translations['calendar-locale.month-names.mar'],
        translations['calendar-locale.month-names.apr'],
        translations['calendar-locale.month-names.may'],
        translations['calendar-locale.month-names.jun'],
        translations['calendar-locale.month-names.jul'],
        translations['calendar-locale.month-names.aug'],
        translations['calendar-locale.month-names.sep'],
        translations['calendar-locale.month-names.oct'],
        translations['calendar-locale.month-names.nov'],
        translations['calendar-locale.month-names.dec'],
      ],
      today: translations['calendar-locale.today'],
      clear: translations['calendar-locale.clear'],
      weekHeader: translations['calendar-locale.week-header'],
    });
  }

  protected processTranslationResponse(source: Observable<TranslationResponse>): any {
    return new Observable(subscriber => {
      source.pipe(
        catchError(async () => {
          await TranslationIndexedDbModel.loadTranslations();

          return of(TranslationIndexedDbModel.translations);
        }),
        map((translations: TranslationResponse) => {
          return this.mapTranslationResponse(translations);
        }),
        switchMap((translations) => {
          return from(TranslationIndexedDbModel.query().bulkPut(translations))
            .pipe(
              catchError(() => of(translations)),
              map(() => translations)
            );
        }),
        switchMap((translations: any[]) => {
          const loggedInUser = this.authService.getUser();
          const localeFromResponse = translations.length === 1 && translations[0].id;

          this.translateService.setDefaultLang('en');
          const language = loggedInUser && loggedInUser.locale ?
            loggedInUser.locale.code || this.translateService.defaultLang :
            localeFromResponse || this.translateService.defaultLang;

          // Make sure the translations are loaded correctly
          return this.translateService.reloadLang(language).pipe(
            take(1),
            switchMap(() => this.translateService.use(language)));
        }),
      ).subscribe({
        next(value: any): void {
          subscriber.next(value);
        },
        error(error: any): void {
          subscriber.error(error);
        },
        complete(): void {
          subscriber.complete();
        },
      });
    });
  }

  protected mapTranslationResponse(translations: TranslationResponse): { id: string, value: Record<string, string> }[] {
    const translationRecords = [];

    for (const language in translations) {
      if (translations.hasOwnProperty(language)) {
        for (const translationKey in translations[language]) {
          if (translationKey === 'base-data' && typeof translations[language][translationKey] === 'object') {
            // Make sure all keys (GUIDs) are lowercase, because MSSQL returns them in uppercases
            translations[language][translationKey] = Object.keys(translations[language][translationKey])
              .reduce((destination, key) => {
                destination[key.toLowerCase()] = translations[language][translationKey][key];

                return destination;
              }, {});
          }
        }

        translationRecords.push({ id: language, value: translations[language] });
      }
    }

    return translationRecords;
  }

  private getHttpParams(groups, locale): HttpParams {
    const joinedGroups: string = groups ? groups.join(',') : null;
    const currentLanguage = locale ?? this.translateService.currentLang;
    let params: HttpParams = new HttpParams();

    if (joinedGroups) {
      params = params.append('groups', joinedGroups);
    }

    if (currentLanguage) {
      params = params.append('locale', currentLanguage);
    }

    return params;
  }
}
