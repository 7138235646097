import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ValueAccessorBase } from '@capturum/ui/api';

@Component({
  selector: 'cap-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(CapturumSelectButtonComponent)],
})
export class CapturumSelectButtonComponent extends ValueAccessorBase<any> {
  @ViewChild(NgModel, { static: true }) public model: NgModel;
  /**
   * Index of the element in tabbing order
   */
  @Input()
  public tabindex = 0;
  /**
   * When specified, allows selecting multiple values
   */
  @Input()
  public multiple: boolean;
  /**
   * Whether selection can be cleared
   */
  @Input()
  public unselectable: boolean;
  /**
   * An array of selectitems to display as the available options
   */
  @Input()
  public options: any[];
  /**
   * Inline style of the component
   */
  @Input()
  public style: any;
  /**
   * Style class of the component
   */
  @Input()
  public styleClass: string = '';
  /**
   * When present, it specifies that the element should be disabled
   */
  @Input()
  public disabled: boolean = undefined;
  /**
   * A property to uniquely identify a value in options
   */
  @Input()
  public dataKey: string;
  /**
   * Name of the label field of an option when an arbitrary objects instead of SelectItems are used as options
   */
  @Input()
  public optionLabel: string;
  /**
   * Name of the value field of an option when an arbitrary objects instead of SelectItems are used as options
   */
  @Input()
  public optionValue: string;
  /**
   * Callback to invoke when a button is clicked
   */
  @Output()
  public optionClick: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when value changes
   */
  @Output()
  public change: EventEmitter<any> = new EventEmitter();
}
