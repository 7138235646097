import { AfterViewInit, Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { ValidatorService, ValueAccessorBase } from '@capturum/ui/api';
import { InputNumber } from 'primeng/inputnumber';

import {
  InputNumberButtonLayout,
  InputNumberCurrencyDisplay,
  InputNumberLocaleMatcher,
  InputNumberMode,
} from './base/input-number.enum';

@Component({
  selector: 'cap-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(CapturumInputNumberComponent)],
})
export class CapturumInputNumberComponent extends ValueAccessorBase<string> implements AfterViewInit {
  /* Text which will show before user click to input */
  @Input() public placeholder: string;
  /* Whether to format the value. */
  @Input() public format: boolean = true;
  /* Displays spinner buttons. */
  @Input() public showButtons: boolean = false;
  /** Layout of the buttons, valid values are "stacked" (default), "horizontal" and "vertical". */
  @Input() public buttonLayout: InputNumberButtonLayout = InputNumberButtonLayout.stacked;
  /** Style class of the increment button. */
  @Input() public incrementButtonClass: string = 'success';
  /** Style class of the decrement button. */
  @Input() public decrementButtonClass: string = 'error';
  /** Icon of the increment button. */
  @Input() public incrementButtonIcon: string = 'fas fa-plus';
  /** Icon of the decrement button. */
  @Input() public decrementButtonIcon: string = 'fas fa-minus';
  /** Locale to be used in formatting. */
  @Input() public locale: string;
  /**
   * The locale matching algorithm to use. Possible values are "lookup" and "best fit";
   * the default is "best fit". See Locale Negotation for details.
   */
  @Input() public localeMatcher: InputNumberLocaleMatcher = InputNumberLocaleMatcher.bestFit;
  /** Defines the behavior of the component, valid values are "decimal" and "currency". */
  @Input() public mode: InputNumberMode = InputNumberMode.decimal;
  /** Text to display before the value. */
  @Input() public prefix: string;
  /** Text to display after the value. */
  @Input() public suffix: string;
  /**
   * The currency to use in currency formatting. Possible values are the ISO 4217 currency codes
   * if the style is "currency", the currency property must be provided.
   */
  @Input() public currency: string;
  /**
   * How to display the currency in currency formatting.
   * Possible values are "symbol" to use a localized currency symbol such as €
   * "code" to use the ISO currency code
   * "name" to use a localized currency name such as "dollar";
   * the default is "symbol".
   */
  @Input() public currencyDisplay: InputNumberCurrencyDisplay = InputNumberCurrencyDisplay.symbol;
  /** Whether to use grouping separators, such as thousands separators or thousand/lakh/crore separators. */
  @Input() public useGrouping: boolean = true;
  /** The minimum number of fraction digits to use. Possible values are from 0 to 20 */
  @Input() public minFractionDigits = 0;
  /** The maximum number of fraction digits to use. Possible values are from 0 to 20 */
  @Input() public maxFractionDigits = 20;
  /** Mininum boundary value. */
  @Input() public min: number;
  /** Maximum boundary value. */
  @Input() public max: number;
  /** Step factor to increment/decrement the value. */
  @Input() public step: number = 1;
  /** Style class of the component. */
  @Input() public styleClass: string;
  /** Maximum number of character allows in the input field. */
  @Input() public maxlength: number;
  /** Specifies tab order of the element. */
  @Input() public tabindex: number;
  /* Disabled input */
  @Input() public disabled: boolean = undefined;
  /**
   * Used to define a string that autocomplete attribute the current element.
   */
  @Input() public autocomplete: string;
  /**
   * The label to be displayed inside the input
   */
  @Input() public label: string;
  /**
   * Select current value from input when will activate it
   */
  @Input() public selectOnFocus: boolean;
  /**
   * When present, it specifies that the input cannot be typed
   */
  @Input() public readonly: boolean = false;

  /* Callback on input blur */
  @Output() public blur: EventEmitter<Event> = new EventEmitter();
  /* Callback on input focus */
  @Output() public focus: EventEmitter<Event> = new EventEmitter();

  public control: FormControl;
  @ViewChild(InputNumber) private inputNumber: InputNumber;

  constructor(private injector: Injector, private validatorService: ValidatorService) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });

    // If input number has a prefix, On click event PrimeNG set then the cursor after the prefix
    // this prevents select the input on focus.
    // That's why we need to override this onInputClick method.
    const originalClickFn = this.inputNumber.onInputClick.bind(this.inputNumber);

    if (!this.inputNumber.readonly && this.prefix && this.selectOnFocus) {
      this.inputNumber.onInputClick = () => null;
    } else {
      this.inputNumber.onInputClick = originalClickFn;
    }

    // Prevents paste in not focused input.
    // That's why we need to override this onPaste method.
    const originalPasteFn = this.inputNumber.onPaste.bind(this.inputNumber);
    this.inputNumber.onPaste = (event) => {
      return document.activeElement === event.target ? originalPasteFn : () => null;
    };
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onBlur(event: Event): void {
    this.blur.emit(event);
    this.touch();
  }

  public onFocus(event: Event): void {
    this.focus.emit(event);

    if (this.selectOnFocus) {
      const input = this.inputNumber?.el?.nativeElement?.querySelector('input') as HTMLInputElement;

      if (input) {
        input.select();
      }
    }
  }
}
