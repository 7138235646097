import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { DropdownFilterConfigItem, DynamicFilterComponent } from '../../interfaces/dynamic-filter.interface';
import { CapturumDynamicFilterLayoutComponent } from '../dynamic-filter-layout/dynamic-filter-layout.component';

@Component({
  selector: 'cap-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CapturumDynamicFilterLayoutComponent,
    CapturumDropdownModule,
    ReactiveFormsModule,
    CapturumSharedModule,
    AsyncPipe,
  ],
})
export class CapturumDropdownFilterComponent implements DynamicFilterComponent<string> {
  @Input()
  public filterConfig: DropdownFilterConfigItem;

  public updateValue: EventEmitter<string> = new EventEmitter<string>();
  public formControl = new FormControl();

  constructor(private cdr: ChangeDetectorRef) {}

  public setValue(value: string): void {
    this.formControl.setValue(value);
    this.cdr.detectChanges();
  }

  public reset(): void {
    this.formControl.reset();
    this.updateValue.emit(null);
  }

  public updateView(): void {
    this.cdr.detectChanges;
  }
}
