import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cap-dynamic-filter-layout',
  templateUrl: './dynamic-filter-layout.component.html',
  styleUrls: ['./dynamic-filter-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule],
})
export class CapturumDynamicFilterLayoutComponent {
  @Input()
  public showReset: boolean;

  @Input()
  public label: string;

  @Input()
  public iconClass: string;

  @Output()
  public resetFilter = new EventEmitter<void>();
}
