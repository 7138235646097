import { AUTH_USE_REFRESH_TOKENS, AuthRefreshTokenConfig } from './../auth.config';
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, interval, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AuthConfig } from '../auth.config';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import User from '../user.interface';
import { isJwtTokenExpired } from '../jwt-helper.util';

interface AuthResult<T> {
  status: string;
  token: string;
  user: T;
  refresh_token?: string;
}

interface SetPasswordData {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}

export interface RefreshTokenData {
  token?: string;
  refresh_token?: string;
  refreshed?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService<USER extends User = never> {
  protected offsetSeconds: number;
  protected intervalSeconds: number;
  protected authenticationState$: BehaviorSubject<boolean>;
  protected isRefreshTokenWachterStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected config: AuthConfig,
    protected http: HttpClient,
    @Optional()
    @Inject(AUTH_USE_REFRESH_TOKENS)
    public authRefreshToken?: AuthRefreshTokenConfig
  ) {
    this.authenticationState$ = new BehaviorSubject<boolean>(this.isAuthenticated());
    this.offsetSeconds = this.authRefreshToken?.offsetSeconds || 60;
    this.intervalSeconds = this.authRefreshToken?.intervalSeconds || 30;
  }

  /**
   * Get the authentication state as Observable
   *
   * @return Observable<boolean>
   */
  public getAuthenticationState(): Observable<boolean> {
    return this.authenticationState$.asObservable();
  }

  /**
   * Set the authentication state,
   * this method should not be called manually from application as this has unwanted side effects
   *
   * @param state: boolean
   * @return void
   */
  public setAuthenticationState(state: boolean): void {
    this.authenticationState$.next(state);
  }

  /**
   * Login the user
   *
   * @param email: string
   * @param password: string
   * @param options: any = null
   * @return Observable<boolean>
   */
  public login(email: string, password: string, options: Record<string, any> = null): Observable<AuthResult<USER>> {
    const customHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return this.http
      .post<AuthResult<USER & { roles: any }>>(
        `${this.config.baseUrl}/auth/login`,
        {
          email: email,
          password: password,
          ...options,
        },
        {
          headers: this.getHeaders(customHeaders),
        }
      )
      .pipe(
        map((result: AuthResult<USER & { roles: any }>) => {
          if (result.status === 'ok') {
            if (result.user) {
              if (result.user.roles !== undefined && result.user.roles.data !== undefined) {
                result.user.roles = result.user.roles.data;
              }

              const user = result.user;
              localStorage.setItem('user', JSON.stringify(user));
            }

            localStorage.setItem('token', result.token);

            if (result.refresh_token) {
              localStorage.setItem('refresh_token', result.refresh_token);
            }

            this.handleSuccessfulLogin(result);
            this.setAuthenticationState(true);

            return result;
          }

          return null;
        }, catchError(this.handleError))
      );
  }

  /**
   * Logout the user
   *
   * @return Observable<boolean>
   */
  public logout(): Observable<boolean> {
    return this.http
      .post(`${this.config.baseUrl}/auth/logout`, null, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((result: any) => {
          if (result.success) {
            this.removeStaleCredentials();
            this.setAuthenticationState(false);
            this.isRefreshTokenWachterStarted.next(false);

            return true;
          }

          return false;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Recover the users' password
   *
   * @param email: string
   * @return Observable<boolean>
   */
  public recoverPassword(email: string): Observable<boolean> {
    return this.http
      .post(
        `${this.config.baseUrl}/auth/recovery`,
        { email: email },
        {
          headers: this.getHeaders(),
        }
      )
      .pipe(
        map((result: any) => {
          return result.status === 'ok';
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Reset the users' password
   *
   * @param data: SetPasswordData
   * @return Observable<any>
   */
  public resetPassword(data: SetPasswordData): Observable<boolean> {
    return this.http
      .post(`${this.config.baseUrl}/auth/reset`, data, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((result: any) => {
          return result.status === 'ok';
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Activate the users' profile
   *
   * @param data: SetPasswordData
   * @return Observable<any>
   */
  public activate(data: SetPasswordData): Observable<Omit<AuthResult<USER>, 'user'>> {
    return this.http
      .post<Omit<AuthResult<USER>, 'user'>>(`${this.config.baseUrl}/auth/activate`, data, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  /**
   * Is the user authenticated based on token in localStorage
   *
   * @return boolean
   */
  public isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  /**
   * Get the token from localStorage
   *
   * @return string
   */
  public getToken(): string {
    return localStorage.getItem('token');
  }

  /**
   * Get the user from localStorage
   *
   * @return User
   */
  public getUser(): User {
    return JSON.parse(localStorage.getItem('user')) as User;
  }

  /**
   * update user details in localStorage
   * @returns Observable<boolean>
   */
  public setUser<T = USER>(user: Partial<T>): Observable<boolean> {
    return new Observable((subscriber) => {
      localStorage.setItem('user', JSON.stringify(user));
      subscriber.next(true);
    });
  }

  /**
   * patch user details in localStorage
   * @returns Observable
   */
  public patchUser<T = USER>(user: Partial<T>): Observable<boolean> {
    return new Observable((subscriber) => {
      if (!!localStorage.getItem('user')) {
        const prevUser = JSON.parse(localStorage.getItem('user')) as User;
        const patchedUser = { ...prevUser, ...user };
        localStorage.setItem('user', JSON.stringify(patchedUser));
        subscriber.next(true);
      } else {
        subscriber.next(false);
      }
    });
  }

  /**
   * Get the AuthConfig
   *
   * @return AuthConfig
   */
  public getConfig(): AuthConfig {
    return this.config;
  }

  /**
   * If the given role exists in users' roles
   *
   * @param role: string | string[]
   * @return boolean
   */
  public hasRole(role: string | string[]): boolean {
    const user = this.getUser();
    const userRoles = user.roles.map((userRole) => {
      return userRole.key;
    });

    if (typeof role === 'string') {
      // Check for exact match
      return userRoles.indexOf(role.toString()) > -1;
    } else if (Array.isArray(role)) {
      // Check if given roles match any user roles
      const intersection = userRoles.filter((u) => role.includes(u));

      return intersection.length > 0;
    }

    return false;
  }

  /**
   * Hook to call after a succesful login
   *
   * @param response: any
   * @return void
   */
  public handleSuccessfulLogin(response: any): void {}

  /**
   * Hook to call after a succesful logout
   *
   * @param response: any
   * @return void
   */
  public handleSuccessfulLogout(response: any): void {}

  /**
   * Get the claims of the token
   *
   * @return Record<string, any>
   */
  public getTokenClaims(): Record<string, any> {
    try {
      return jwt_decode(this.getToken());
    } catch (error) {
      return null;
    }
  }

  /**
   * Remove credentials from localStorage
   *
   * @return void
   */
  public removeStaleCredentials(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('refresh_token');
  }

  /**
   * Get Http headers
   * @returns new HttpHeaders
   */
  public getHeaders(customHeaders?: HttpHeaders): HttpHeaders {
    if (customHeaders) {
      return customHeaders;
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    if (this.getToken()) {
      headers.append('Authorization', 'Bearer ' + this.getToken());
    }

    return headers;
  }

  public getRefreshToken(): Observable<RefreshTokenData> {
    const refresh_token = localStorage.getItem('refresh_token');

    return this.http
      .post<{ data: RefreshTokenData }>(
        `${this.config.baseUrl}/auth/refresh-token`,
        { refresh_token },
        {
          headers: this.getHeaders(),
        }
      )
      .pipe(
        map((result) => ({
          refreshed: true,
          ...result.data,
        }))
      );
  }

  public isTokenExpired(): Observable<boolean> {
    const jwt = this.getTokenClaims();

    return new Observable((observer) => {
      if (jwt) {
        const tokenExpired = isJwtTokenExpired(jwt, this.offsetSeconds);

        observer.next(tokenExpired);
        observer.complete();
      } else {
        observer.error();
      }
    });
  }

  /**
   * Watch refresh token is expired, then request a new token
   * @returns Observable<RefreshTokenData>
   */
  public watchRefreshToken(): Observable<RefreshTokenData> {
    const token = new BehaviorSubject<RefreshTokenData>({
      refreshed: false,
      token: null,
      refresh_token: null,
    });

    if (!this.refreshTokenWachterIsStarted()) {
      this.isRefreshTokenWachterStarted.next(true);

      this.checkForRefreshToken()
        .pipe(
          switchMap((expired) => {
            return expired
              ? this.getRefreshToken()
              : of({
                  refreshed: false,
                  token: null,
                  refresh_token: null,
                });
          })
        )
        .subscribe({
          next: (result) => {
            let tokenSubjectValue: RefreshTokenData = {
              refreshed: false,
              token: null,
              refresh_token: null,
            };

            if (result.refreshed) {
              tokenSubjectValue = {
                refreshed: true,
                token: result.token,
                refresh_token: result.refresh_token,
              };

              localStorage.setItem('token', result.token);
              localStorage.setItem('refresh_token', result.refresh_token);
            }

            token.next(tokenSubjectValue);
          },
          error: () => this.logout(),
        });
    }
    return token.asObservable();
  }

  private checkForRefreshToken(): Observable<boolean> {
    const intervalSeconds = this.intervalSeconds * 1000;

    return interval(intervalSeconds).pipe(
      filter(() => this.isRefreshTokenWachterStarted.value),
      switchMap(() => this.isTokenExpired()),
      takeUntil(this.isRefreshTokenWachterStarted.asObservable().pipe(filter((value) => !value)))
    );
  }

  private refreshTokenWachterIsStarted(): boolean {
    return this.isRefreshTokenWachterStarted.getValue();
  }

  /**
   * Handle the thrown error
   *
   * @param error: any
   * @return Observable<never>
   */
  private handleError(error: any): Observable<never> {
    return throwError(error);
  }
}
