import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, NgControl, NgModel } from '@angular/forms';
import { CapturumTemplateDirective, ValidatorService, ValueAccessorBase } from '@capturum/ui/api';
import { Subject, takeUntil } from 'rxjs';


/**
 * A Input component
 *
 * Example:
 * ```html
 *  <cap-input label="button"></cap-input>
 * ```
 */
@Component({
  selector: 'cap-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(CapturumInputComponent)],
})
export class CapturumInputComponent
  extends ValueAccessorBase<string>
  implements AfterViewInit, AfterContentInit, OnDestroy
{
  /**
   * Value of the input
   */
  @Input() public inputValue: any;
  /**
   * The placeholder for the input to display when there is no input filled in
   */
  @Input() public placeholder = '';
  /**
   * The label to be displayed inside the input
   */
  @Input() public label: string;
  /**
   * The optional filter for input
   */
  @Input() public filter:
    | string
    | 'money'
    | 'num'
    | 'hex'
    | 'email'
    | 'alpha'
    | 'alphanum'
    | 'pnum'
    | 'pint'
    | 'int'
    | RegExp = new RegExp('');
  /**
   * The value that determines if the input is disabled
   */
  @Input() public disabled: boolean = undefined;
  /**
   * The variable that configures the input to be read only or not
   */
  @Input() public readonly: boolean;
  /**
   * When present, it specifies that an input field must be filled out before submitting the form
   */
  @Input() public required: boolean;
  /**
   * The type for the input
   */
  @Input() public type: string = 'text';
  /**
   * The icon class of the icon which should be left displayed beside the input
   */
  @Input() public iconLeft: string;
  /**
   * The icon class of the icon which should be right displayed beside the input
   */
  @Input() public iconRight: string;
  /**
   * The styleClass of the Input component
   */
  @Input() public styleClass: string = '';
  /**
   * The minimum number to allow in a numeric input
   */
  @Input() public min: string;
  /**
   * The maximum number to allow in a numeric input
   */
  @Input() public max: string;
  /**
   * The maximum length of characters to allow in the input
   */
  @Input() public maxLength: string;
  /**
   * The minimum length of characters to allow in the input
   */
  @Input() public minLength: string;
  /**
   * The variable to be set when you want to enable autocomplete
   */
  @Input() public autocomplete: string = 'off';
  /**
   * The variable that determines whether the input field should be autofocused onInit
   */
  @Input() public autofocus: boolean = false;
  /**
   * The pattern for input
   */
  @Input() public pattern: string;
  /**
   * Specifies if the tooltip is present
   */
  @Input() public hasTooltip: boolean;

  /**
   * The transformation function to be executed when retrieving the value
   */
  @Input() public getValueTransform: (value: string) => any = undefined;

  /**
   * The transformation function to be executed when setting the value
   */
  @Input() public setValueTransform: (value: any) => string = undefined;

  /**
   * Define whether the input should be readonly
   */
  @Input() public readOnly: boolean;
  /**
   * Index of the element in tabbing order
   */
  @Input() public tabindex: number;
  /**
   * Select current value from input when will activate it
   */
  @Input() public selectOnFocus: boolean;
  /**
   * Float label
   */
  @Input() public floatLabel = false;
  /**
   * The action you want to emit when there is a change in the input
   */
  @Output() public onInput: EventEmitter<any> = new EventEmitter();
  /**
   * The action you want to emit when the input loses focus
   */
  @Output() public blur: EventEmitter<Event> = new EventEmitter();
  /**
   * The action you want to emit when input is in focus
   */
  @Output() public focus: EventEmitter<Event> = new EventEmitter();
  /**
   * The action you want to emit when input a keyboard key up event happens
   */
  @Output() public onKeyUp: EventEmitter<KeyboardEvent> = new EventEmitter();
  @ViewChild(NgModel, { static: true }) public model: NgModel;
  @ViewChild('input', { static: false }) public input: ElementRef<HTMLInputElement>;

  /**
   * The templates given from the CapturumTemplateDirective
   */
  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<any>;

  /**
   * The form control class to control the input
   */
  public control: FormControl;
  public contentRightTemplate: TemplateRef<string>;
  public contentLeftTemplate: TemplateRef<string>;

  private destroy$ = new Subject<void>();

  constructor(private injector: Injector, private validatorService: ValidatorService) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }

  /**
   * switch templates based on the content children
   */
  public ngAfterContentInit(): void {
    this.applyTemplate();

    this.templates.changes.pipe(takeUntil(this.destroy$)).subscribe((templates) => {
      this.templates = templates;

      this.applyTemplate();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onInputEvent($event: any): void {
    this.touch();
    this.onInput.emit($event);
  }

  public onBlur(event: Event): void {
    this.touch();
    this.blur.emit(event);
  }

  public onFocus(event: Event): void {
    this.focus.emit(event);

    if (this.selectOnFocus) {
      this.input?.nativeElement?.select();
    }
  }

  private applyTemplate(): void {
    this.templates.forEach((template) => {
      switch (template.getType()) {
        case 'content-right':
          this.contentRightTemplate = template.template;
          break;
        case 'content-left':
          this.contentLeftTemplate = template.template;
          break;
      }
    });
  }
}
