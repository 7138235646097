import { AfterViewInit, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { ValidatorService, ValueAccessorBase } from '@capturum/ui/api';

@Component({
  selector: 'cap-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(CapturumInputMaskComponent)]
})
export class CapturumInputMaskComponent extends ValueAccessorBase<string> implements AfterViewInit {
  /* Text which will show before user click to input */
  @Input() public placeholder: string;
  /* Label text */
  @Input() public label: string;
  /* Pattern for mask https://www.primefaces.org/primeng/#/inputmask */
  @Input() public mask: string;
  /* Disabled input */
  @Input() public disabled: boolean = undefined;
  /**
   * Specifies if the tooltip is present
   */
  @Input() public hasTooltip: boolean;
  /* Callback on input blur */
  @Output() public blur: EventEmitter<void> = new EventEmitter();
  /* Callback on complete pattern */
  @Output() public complete: EventEmitter<void> = new EventEmitter();
  /* Callback on input focus */
  @Output() public focus: EventEmitter<void> = new EventEmitter();

  public control: FormControl;

  constructor(private injector: Injector, private validatorService: ValidatorService) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onBlur(): void {
    this.blur.emit();
    this.touch();
  }

  public onFocus(): void {
    this.focus.emit();
  }
}
