import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompleteBaseForm } from '../../../../shared/components/complete-base-form';
import { BaseDataValueApiModel, BaseDataValueAttribute } from '../..';
import { LocaleApiModel } from '../../../locale';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'emc-base-data-value-form',
  templateUrl: './base-data-value-form.component.html',
  styleUrls: ['./base-data-value-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseDataValueFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BaseDataValueFormComponent),
      multi: true,
    },
  ],
})
export class BaseDataValueFormComponent extends CompleteBaseForm implements AfterViewInit, OnChanges {
  get locales(): LocaleApiModel[] {
    return this._locales;
  }

  @Input()
  set locales(value: LocaleApiModel[]) {
    this._locales = value;
  }

  @Input()
  public isEditable: boolean;
  @Input()
  public parentOptions: SelectItem[];
  @Input()
  public baseDataAttributes: BaseDataValueAttribute[];

  @Input()
  public fields: FormlyFieldConfig[];
  public updateValue$ = new BehaviorSubject({});
  public formModel: any = {};

  private _locales: LocaleApiModel[];

  constructor(private changeDetectorRef: ChangeDetectorRef, private translateService: TranslateService) {
    super(changeDetectorRef);
  }

  public ngAfterViewInit(): void {
    this.listenToFormChanges();
    this.changeDetectorRef.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.locales && this.baseDataAttributes && this.parentOptions && this.fields) {
      this.populateFormlyFieldsConfigs();
    }
  }

  public writeValue(value: Partial<BaseDataValueApiModel>): void {
    this.formModel = value;
  }

  /**
   * Set formly fields
   */
  public populateFormlyFieldsConfigs(): void {
    this.fields = [
      {
        key: 'value',
        type: 'input',
        props: {
          translateLabel: this.translateService.stream('base-data-form.value'),
          required: true,
          disabled: !this.isEditable,
        },
      },
      {
        key: 'parent_id',
        type: 'dropdown',
        props: {
          translateLabel: this.translateService.stream('base-data-form.parent'),
          translatePlaceholder: this.translateService.stream('placeholder.select'),
          disabled: !this.isEditable,
          options: this.parentOptions,
        },
      },
      ...this._locales.map((locale) => {
        return {
          key: `translations.${locale.id}`,
          type: 'input',
          props: {
            translateLabel: locale.name,
            required: true,
          },
        };
      }),
      ...(this.fields || []),
    ];

    if (this.baseDataAttributes && this.baseDataAttributes.length > 0) {
      this.fields = [
        ...this.fields,
        {
          template: `<hr><p><b>${this.translateService.instant('base-data-form.attributes.label')}</b></p>`,
        },
        ...this.baseDataAttributes.map((attr) => {
          let type = 'input';
          switch (attr[attr.key]) {
            case 'boolean':
              type = 'inputSwitch';
              break;
          }

          return {
            key: `attributes.${attr.key}`,
            type: type,
            props: {
              translateLabel: of(attr.key),
            },
          };
        }),
      ];
    }
  }
}
