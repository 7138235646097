import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { CommonModule } from '@angular/common';
import { ButtonConfig, HeaderTabsConfig } from './interfaces/header-tabs-config.interface';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TabMenuModule } from 'primeng/tabmenu';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumTemplateDirective, Directions } from '@capturum/ui/api';
import { MenuItem } from 'primeng/api';
import { SplitButtonItem } from '@capturum/ui/split-button';
import { ThreeDotMenuButtonComponent } from './components/three-dot-menu-button/three-dot-menu-button.component';

@Component({
  selector: 'cap-header-tabs',
  templateUrl: 'header-tabs.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    UserMenuComponent,
    TabMenuModule,
    CapturumButtonModule,
    ThreeDotMenuButtonComponent,
  ],
})
export class CapturumHeaderTabsComponent implements OnInit, AfterContentInit {
  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<CapturumTemplateDirective>;
  @Input()
  public config: HeaderTabsConfig;
  @Input()
  public userMenuTemplate: TemplateRef<any>;
  @Input()
  public headerButtonTemplate: TemplateRef<any>;
  @Input()
  public selectedIndex = 0;
  @Output()
  public tabClicked: EventEmitter<number> = new EventEmitter();

  public direction = Directions;
  public backButtonIconClass = 'fas fa-arrow-left';
  public currentButtonConfig: ButtonConfig;
  public menuButtonItems: SplitButtonItem[];

  constructor(public route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.updateButtonConfig(this.selectedIndex);
  }

  public ngAfterContentInit(): void {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'userMenu':
          this.userMenuTemplate = item.template;
          break;
        case 'headerButton':
          this.headerButtonTemplate = item.template;
          break;
      }
    });
  }

  public onActiveItemChange(event: MenuItem): void {
    this.updateButtonConfig(event.index);
    this.tabClicked.emit(event.index);
  }

  public updateButtonConfig(index: number): void {
    if (this.config?.headerMenuItems) {
      this.currentButtonConfig = this.config.headerMenuItems[index]?.buttonConfig;
      this.menuButtonItems = this.config.headerMenuItems[index]?.menuButtonItems;
    }
  }

  public execute(item: MenuItem): void {
    if (item.onClick) {
      item.onClick();
    }
  }
}
