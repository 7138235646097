<cap-dynamic-filter-layout
  class="dynamic-calendar-filter"
  [label]="filterConfig.showLabel ? filterConfig.label : ''"
  [iconClass]="filterConfig.icon"
  [showReset]="formControl.value && (filterConfig.resetFilter ?? true)"
  (resetFilter)="reset()">
  <cap-calendar
    [attr.data-test]="'filter-' + filterConfig.field"
    [readonlyInput]="true"
    [formControl]="formControl"
    [hideOnDateTimeSelect]="true"
    [showIcon]="true"
    [appendTo]="'body'"
    [tabindex]="filterConfig | dateFilterTabindex"
    [minDate]="filterConfig.minDate | observablePipe | async"
    [maxDate]="filterConfig.maxDate | observablePipe | async"
    [hideOnRangeSelect]="true"
    [icon]="'pi pi-chevron-down'"
    [view]="filterConfig.view || 'date'"
    [dateFormat]="filterConfig.dateFormat || 'dd-mm-yy'"
    [disabled]="filterConfig.disabled | observablePipe | async"
    [setTimeZone]="filterConfig.timezone ?? true"
    [selectionMode]="filterConfig.matchMode | dateFilterSelectionModePipe"
    [placeholder]="filterConfig.placeholder"
    [iconDisplay]="filterConfig.iconDisplay"
    (select)="handleDateFilterChange(filterConfig.matchMode)">
  </cap-calendar>
</cap-dynamic-filter-layout>
