import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  input,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CapturumDialogService, LocalStorageService, TableRouteConfigService } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumSidebarModule } from '@capturum/ui/sidebar';
import { TranslateModule } from '@ngx-translate/core';
import { ChipsModule } from 'primeng/chips';
import {
  ActiveFilters,
  ActiveTokenFilter,
  DynamicFilterConfig,
  FilterConfigItem,
} from '../../interfaces/dynamic-filter.interface';
import { FilterFieldPipe } from '../../pipes/filter-field.pipe';
import { DynamicFiltersUtils } from '../../utils/dynamic-filters.utils';
import { CapturumDynamicFilterComponent } from '../dynamic-filter/dynamic-filter.component';
import { DynamicFiltersSidebarComponent } from '../dynamic-filters-sidebar/dynamic-filters-sidebar.component';

@Component({
  selector: 'cap-dynamic-filters',
  templateUrl: './dynamic-filters.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CapturumDialogService],
  standalone: true,
  imports: [
    CapturumButtonModule,
    TranslateModule,
    CapturumSidebarModule,
    ChipsModule,
    FormsModule,
    CapturumDynamicFilterComponent,
    NgClass,
    FilterFieldPipe,
    DynamicFiltersSidebarComponent,
  ],
})
export class CapturumDynamicFiltersComponent implements OnInit, OnChanges {
  /**
   * The configuration of the filters that should be rendered
   */
  @Input()
  public filterConfig: DynamicFilterConfig = { filters: [] };

  /**
   * The key to use in localstorage for storing the filters
   */
  @Input()
  public storageKey: string;

  /**
   * Define whether the reset button should be shown
   */
  @Input()
  public routeFilters = false;

  /**
   * The style class to be applied to the container element of the filters
   */
  @Input()
  public containerStyleClass = '';

  /**
   * The style class to be applied to each individual filter component
   */
  @Input()
  public filterStyleClass = '';

  /**
   * Define whether the reset button should be shown
   */
  @Input()
  public showResetButton = true;

  /**
   * Define whether the refresh button should be shown
   */
  @Input()
  public showRefreshButton = true;

  @Input()
  public maxFiltersShown = 40;

  public triggerFilterChangeOnReset = input<boolean>(true);

  public filteredTokens: any[] = [];
  /**
   * Callback to execute whenever any of the filter values change
   */
  @Output()
  public activeFiltersChange = new EventEmitter<ActiveFilters[]>();

  @Input()
  public maxTokens = 10;

  @Input()
  public sidebarFiltering = false;

  @Output()
  public onResetFilters = new EventEmitter<ActiveFilters[]>();

  public SEARCH_BAR = 'global_search';
  public showSidebar: boolean;
  public activeSidebarFilters: ActiveFilters[] = [];
  public showFilters: FilterConfigItem[] = [];
  public hideFilters: FilterConfigItem[] = [];

  public _activeFilters: ActiveFilters[] = [];

  public get activeFilters(): ActiveFilters[] {
    return this._activeFilters;
  }

  @Input()
  public set activeFilters(filters: ActiveFilters[]) {
    this._activeFilters = filters;
  }

  constructor(
    private tableRouteConfigService: TableRouteConfigService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
  ) {}

  public ngOnInit(): void {
    this.checkActiveFilters();

    this.filterConfig?.filters.forEach((filterConfig) => {
      if (
        !this._activeFilters.find((activeFilter) => {
          return activeFilter.field === filterConfig.field;
        })
      ) {
        if (filterConfig.default !== undefined) {
          this._activeFilters = [
            ...this._activeFilters,
            {
              value: filterConfig.default,
              matchMode: filterConfig.matchMode,
              field: filterConfig.field,
            },
          ];
        }
      }
    });

    this.checkShownFilterKeys();

    this.activeFiltersChange.emit(this._activeFilters);
    this.updateFilterTokens();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.filterConfig && !changes?.filterConfig?.firstChange) {
      this.checkShownFilterKeys();
    }
  }

  public checkActiveFilters(): void {
    let storageFilters = [];

    if (this.routeFilters) {
      storageFilters = this.retrieveFiltersFromQueryParams();

      if (!storageFilters.length) {
        storageFilters = this.getStorageFilters();
      }
    } else if (this.storageKey) {
      storageFilters = this.getStorageFilters();
    }

    if (storageFilters.length) {
      this._activeFilters = storageFilters.reduce((acc, storageFilter) => {
        const activeFilter = this._activeFilters.find((activeFilter) => {
          return activeFilter.field === storageFilter.field;
        });

        if (
          !activeFilter &&
          (Array.isArray(storageFilter?.value)
            ? storageFilter?.value?.length
            : storageFilter?.value !== undefined && storageFilter.value !== null)
        ) {
          return [...acc, storageFilter];
        }

        return acc;
      }, this._activeFilters);
    } else if (this.filterConfig?.routeFilters?.length) {
      this._activeFilters = this.filterConfig?.routeFilters;

      if (this.routeFilters) {
        this.saveFiltersInQueryParams(this._activeFilters);
      }

      if (this.routeFilters || this.storageKey) {
        this.storeValues(this._activeFilters);
      }
    }
  }

  public handleUpdateValue(updatedFilter: { field: string; value: any }): void {
    if (
      !this._activeFilters.some((filter) => {
        return filter.field === updatedFilter.field;
      })
    ) {
      this._activeFilters = [
        ...this._activeFilters,
        {
          ...updatedFilter,
          matchMode: this.filterConfig.filters.find((filter) => {
            return filter.field === updatedFilter.field;
          })?.matchMode,
        },
      ];
    } else {
      this._activeFilters = this._activeFilters.reduce((acc, filter) => {
        if (filter.field === updatedFilter.field) {
          return [...acc, { ...filter, value: updatedFilter.value }];
        }

        return [...acc, filter];
      }, []);
    }

    this._activeFilters = this._activeFilters.filter((filter) => {
      return Array.isArray(filter?.value)
        ? filter?.value?.length
        : filter?.value !== undefined && filter?.value !== null;
    });

    if (this.routeFilters) {
      this.saveFiltersInQueryParams(this._activeFilters);
    }

    if (this.routeFilters || this.storageKey) {
      this.storeValues(this._activeFilters);
    }

    this.activeFiltersChange.emit(this._activeFilters);
    this.updateFilterTokens();
  }

  public resetFilters(): void {
    this._activeFilters = this.getDefaultFilters() || [];

    if (this.filterConfig?.routeFilters?.length) {
      this._activeFilters = this.filterConfig.routeFilters;
    }

    if (this.routeFilters) {
      this.saveFiltersInQueryParams(this._activeFilters);
    }

    if (this.routeFilters || this.storageKey) {
      this.storeValues(this._activeFilters);
    }

    if (this.triggerFilterChangeOnReset()) {
      this.activeFiltersChange.emit(this._activeFilters);
    }

    this.updateFilterTokens();
    this.onResetFilters.emit(this._activeFilters);
  }

  public refreshFilters(): void {
    this.activeFiltersChange.emit(this._activeFilters);
  }

  public openFilterSidebar(): void {
    this.showSidebar = true;
    this.activeSidebarFilters = [...this._activeFilters];
  }

  public applySidebarFilters(filters: ActiveFilters[]): void {
    this.showSidebar = false;
    this._activeFilters = filters.filter((filter) => {
      return Array.isArray(filter?.value)
        ? filter?.value?.length
        : filter?.value !== undefined && filter?.value !== null;
    });

    this.storeValues(this._activeFilters);

    this.activeFiltersChange.emit(this._activeFilters);
    this.updateFilterTokens();
  }

  public handleSidebarFilterChange(filters: ActiveFilters[]): void {
    this._activeFilters = filters;
  }

  public removeFilter(removedFilter: ActiveTokenFilter): void {
    this.handleUpdateValue({
      ...removedFilter,
      value: null,
    });
  }

  public saveFiltersInQueryParams(filters: ActiveFilters[]): void {
    const newFilters = [...filters];
    const previousFilters = this.retrieveFiltersFromQueryParams();

    // We have to update the already existing query params with null value
    // in order to delete them
    previousFilters.forEach((prevFilter) => {
      const newFilter = newFilters.some((newFilter) => {
        return newFilter.field === prevFilter.field;
      });

      if (!newFilter) {
        newFilters.push({ ...prevFilter, value: null });
      }
    });

    this.tableRouteConfigService.saveConfigInParams(DynamicFiltersUtils.generateQueryUrlFromActiveFilters(newFilters));
  }

  private getStorageFilters(): ActiveFilters[] {
    const storageFilters = JSON.parse(localStorage.getItem(this.getFilterStorageKey()));

    return this.storageKey ? storageFilters || [] : [];
  }

  private storeValues(filters: Record<string, any>): void {
    localStorage.setItem(this.getFilterStorageKey(), JSON.stringify(filters));
  }

  private getFilterStorageKey(): string {
    return `${this.storageKey}_filters`;
  }

  private retrieveFiltersFromQueryParams(): ActiveFilters[] {
    return Object.keys(this.activatedRoute.snapshot.queryParams || {})?.length
      ? DynamicFiltersUtils.retrieveQueryParamFilters(this.activatedRoute.snapshot.queryParams)
      : [];
  }

  private getDefaultFilters(): ActiveFilters[] {
    let activeFilters = [];

    this.filterConfig?.filters.forEach((filterConfig) => {
      if (filterConfig.default !== undefined && filterConfig.default !== null) {
        activeFilters = [
          ...activeFilters,
          {
            value: filterConfig.default,
            matchMode: filterConfig.matchMode,
            field: filterConfig.field,
          },
        ];
      }
    });

    return activeFilters;
  }

  private getShownFiltersStorageKey(): string {
    return `${this.storageKey}-shownFilters`;
  }

  private updateFilterTokens(): void {
    this.filteredTokens = this.activeFilters
      .slice(0, this.maxTokens)
      .filter((filter) => {
        return filter.value !== undefined && filter.value !== null && filter.value !== '';
      })
      .map((filter) => {
        return {
          ...filter,
          label: this.filterConfig?.filters?.find((filterConfig) => {
            return filterConfig.field === filter.field;
          })?.label,
        };
      });
  }

  private checkShownFilterKeys(): void {
    const shownFilterKeys = this.localStorageService.getItem(this.getShownFiltersStorageKey());

    if (!shownFilterKeys) {
      this.showFilters = this.filterConfig.filters.filter((filter) => {
        return !filter.hidden;
      });
    } else {
      this.showFilters = this.filterConfig.filters.filter((filter) => {
        return shownFilterKeys.includes(filter.field);
      });

      this.hideFilters = this.filterConfig.filters.filter((filter) => {
        return !shownFilterKeys.includes(filter.field);
      });
    }
  }
}
