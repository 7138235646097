<div class="translated-field-wrapper" [hidden]="to.readonly" [attr.data-test]="'builder-' + field.key">
  <div class="field-locale-selector">
    <p-splitButton
      #localeButton
      [model]="localeOptions"
      [label]="selectedLocale"
      (onClick)="toggleMenu($event)"></p-splitButton>
  </div>

  <div class="translated-field-group">
    <div class="field-group-addon">
      <span class="flag" [ngClass]="'flag-' + selectedLocale"></span>
    </div>

    <div class="field-group-input">
      @for (fieldGroupField of field.fieldGroup; track $index) {
        <div class="repeat-field-row">
          <formly-field class="repeat-field-form" [field]="fieldGroupField"></formly-field>
        </div>
      }
    </div>
  </div>
</div>

<cpb-readonly-container [hidden]="!to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
  {{ formControl.value | displayValueByCurrentLocale | default: to?.defaultEmptyValue }}
</cpb-readonly-container>
