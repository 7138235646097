import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LazyLoadEvent, SelectItem, SortMeta } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { LocaleSettings } from 'primeng/calendar';
import { Table } from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import { CalendarConfig, CapturumCalendarService } from '@capturum/ui/calendar';
import { TableFiltersSettingsComponent } from './components/table-filters-settings/table-filters-settings.component';
import { TableFiltersComponent } from './components/table-filters/table-filters.component';
import { TableSorting } from './components/table-sort/table-sort.component';
import {
  CapturumTemplateDirective,
  Directions,
  FilterMatchMode,
  TableAction,
  TableActionEvent,
  TableColumn,
  TableColumnTypes,
  TableFilter,
  TableFilterStyles,
  TableSortingMode,
  TableText,
} from '@capturum/ui/api';
import { take } from 'rxjs/operators';
import { isValid } from 'date-fns';

@Component({
  selector: 'cap-data-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterContentInit, OnDestroy {

  /**
   * Name of formgroup to pass to rows
   */
  @Input() public formGroup: FormGroup;

  /**
   * Do we want to use regular rows or form-rows?
   */
  @Input() public useFormRows: boolean;

  /**
   * The property that defines a unique state key for remembering filters, sorting and pagination. If not set, then state is not used.
   */
  @Input() public stateKey: string;
  /**
   * Callback to invoke when a action is clicked
   */
  @Output() public onActionClick = new EventEmitter<TableActionEvent>();
  /**
   * Callback to invoke when a row is selected
   */
  @Output() public onRowClick = new EventEmitter<any>();
  /**
   * Callback to invoke when paging, sorting or filtering happens in lazy mode
   */
  @Output() public onLazyLoad = new EventEmitter<LazyLoadEvent>();
  /**
   * Callback to invoke when delete button of a row is clicked
   */
  @Output() public onDeleteRow = new EventEmitter<number>();
  /**
   * Callback to invoke when edit button of a row is clicked
   */
  @Output() public onEditRow = new EventEmitter<number>();
  /**
   * Callback to invoke when icon of a row is clicked
   */
  @Output() public onIconClick = new EventEmitter<{ id: number; field: string }>();
  /**
   * Callback to invoke when state of row checkbox changes. This passes all selected rows
   */
  @Output() public onCheckboxToggle = new EventEmitter<any[]>();
  /**
   * Callback to invoke when state of row checkbox changes. This passes the selected row
   */
  @Output() public onRowToggle = new EventEmitter<any>();
  /**
   * Callback to invoke when the table is sorted
   */
  @Output() public onSort = new EventEmitter<any>();
  /**
   * Callback to invoke when a filter changes
   */
  @Output() public onFilter = new EventEmitter<TableFilter>();
  /**
   * Callback to invoke when changing pages
   */
  @Output() public onPage = new EventEmitter<any>();
  /**
   * Callback to invoke when number of rows changes
   */
  @Output() public onNumRowsChange = new EventEmitter<any>();
  /**
   * Callback to invoke when search input was changed
   */
  @Output() public onSearch = new EventEmitter<string>();
  /**
   * Callback to invoke when the visibility of a column is changed
   */
  @Output() public onColumnVisibleChange = new EventEmitter();
  /**
   * Callback to invoke when the manageable column is changed
   */
  @Output() public onManageableColumnChange: EventEmitter<{ column: TableColumn; checked: boolean }> = new EventEmitter();
  /**
   * Callback to invoke when the visibility of a filter is changed
   */
  @Output() public onFilterVisibleChange = new EventEmitter<TableColumn[]>();
  /**
   * Define actions in settings dropwdown
   */
  @Input() public rowActions: TableAction[];
  /**
   * Define row actions icon
   */
  @Input() public rowActionsIcon: string = 'fas fa-ellipsis-v';
  /**
   * The paginator to be used by the table
   */
  @Input() public paginator: {
    rows: any;
    total: number;
    current_page: number;
    total_pages: number;
    per_page: number;
    first?: number;
  };
  /**
   * Options for 'per page' dropdown
   */
  @Input() public perPageOptions: SelectItem[];
  /**
   * An array of objects to display
   */
  @Input() public tableValue: any[];
  /**
   * Value of selected rows
   */
  @Input() public selectedValue: any[] = [];
  /**
   * A property to uniquely identify a record in data
   */
  @Input() public dataKey: string = null;
  /**
   * Indicates whether the table has pagination
   */
  @Input() public pagination = true;
  /**
   * Indicates if table should use lazyLoading
   */
  @Input() public lazyLoading = true;
  /**
   * Whether to call lazy loading on initialization
   */
  @Input() public lazyLoadOnInit: boolean = true;
  /**
   * Indicates if table records are selectable
   */
  @Input() public selectable = false;
  /**
   * Indicates if there is a checkbox input in the columns to select all rows
   */
  @Input() public selectAllRows = false;
  /**
   * Indicated if columns in table are filterable
   */
  @Input() public filterable = true;
  /**
   * Indicated if filters in table are editable
   */
  @Input() public manageableFilters = false;
  /**
   * Indicated manageable title
   */
  @Input() public manageableTitle: string;
  /**
   * Define the filtering for the table
   */
  @Input() public filters: {
    field: string;
    value: any;
    operator?: string;
  }[];
  /**
   * Define whether the table rows are clickable
   */
  @Input() public clickable?: boolean;
  /**
   * Define whether the rows are deletable. This will display a trash icon at the table rows
   */
  @Input() public deletable?: boolean;
  /**
   * Define whether the rows are editable. This will display a pencil icon at the table rows
   */
  @Input() public editable = true;
  /**
   * The property that defines whether the table rows are expandable or not, a dataKey is required if true
   */
  @Input() public expandable = false;
  /**
   * If a table element is deletable and editable this is true
   */
  @Input() public actions?: boolean;
  /**
   * If table is scrollable
   */
  @Input() public scrollable: boolean;
  /**
   * Define width for frozen columns
   */
  @Input() public frozenWidth: string = '300px';
  /**
   * Define width for unfrozen columns
   */
  @Input() public unFrozenColumnWidth: string;
  /**
   * Make rows to have same height
   */
  @Input() public makeRowsTheSameHeight: boolean;
  /**
   * Define height for table
   */
  @Input() public scrollHeight: string;
  /**
   * Leave this empty when clickable is false or the route should remain the current route
   */
  @Input() public route?: string;
  /**
   * Define whether the table is in a loading state and should therefor show a loading spinner
   */
  @Input() public loading?: boolean;
  /**
   * Display text for footer paginator
   */
  @Input() public showFooterText = true;
  /**
   * The property that decides whether a row is editable or not
   */
  @Input() public editableProperty: string;
  /**
   * The property that defines whether the table is sortable or not
   */
  @Input() public sortable = true;
  /**
   * The property that decides the sortMode. Options are single and multiple
   */
  @Input() public sortMode = 'single';
  /**
   * The locale which will use by date table filter
   */
  @Input() public calendarLocale: LocaleSettings;
  /**
   * Define calendar's default ranges
   */
  @Input() public calendarDefaultRanges: { label: string | Observable<string>, value: any }[] = [];
  /**
   * The property that decides the sorting configuration based on enum
   */
  @Input() public tableSortingType: TableSortingMode = TableSortingMode.DEFAULT;
  /**
   * The property that decides where the icons are located
   */
  @Input() public iconPosition: Directions = Directions.left;
  /**
   * Define whether the filters should be collapsible
   */
  @Input() public collapsibleFilters = true;
  /**
   * Define whether the filters should be resettable
   */
  @Input() public resettableFilters = false;
  /**
   * Display button to reset all filters
   */
  @Input() public resettableFiltersButton = true;
  /**
   * Indicates whether the table is searchable
   */
  @Input() public searchable: boolean = false;
  /**
   * Define the placeholder text for the search input field
   */
  @Input() public searchPlaceholder: string = '';
  /**
   * Define whether the columns can be reorderable
   */
  @Input() public reorderableColumns = false;
  /**
   * Define whether the columns can be manageable(show or hide a column)
   */
  @Input() public manageableColumns = false;
  /**
   * Define the icon for manageable columns
   */
  @Input() public manageableColumnsIcon: string = 'fas fa-bars';
  /**
   * Define whether an onFilter or lazyload event should be emitted
   */
  @Input() public usePrimeFiltering: boolean;
  /**
   * The timeout of when the filter event should be emitted
   */
  @Input() public filterTimeout = 300;
  /**
   * Set to true if you want to manage the state of the table in Primeng table instead of the custom implementation
   */
  @Input() public ignoreTableStateManagement: boolean;
  /**
   * Optional custom template for expanded table rows
   */
  @Input() public expansionTemplate: TemplateRef<string>;
  /**
   * When enabled, columns can be resized using drag and drop.
   */
  @Input() public resizableColumns: boolean;
  /**
   * A reference to the PrimeNg table
   */
  @ViewChild('pt') public primeTableRef: Table;
  /**
   * A reference to the TableFilters component
   */
  @ViewChild('tableFilters') public tableFiltersRef: ElementRef<TableFiltersComponent>;
  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<any>;
  public lazyLoadEvent: LazyLoadEvent;
  public sortOrderFields: TableSorting[];
  public tableSortingMode: typeof TableSortingMode = TableSortingMode;
  public directions: typeof Directions = Directions;
  public afterFiltersTemplate: TemplateRef<string>;
  public rightFiltersTemplate: TemplateRef<string>;
  public hideFilters: boolean;
  public activeFilters: TableFilter = {};
  public tableColumnTypes = TableColumnTypes;
  public filterMatchMode = FilterMatchMode;
  public stateInitialized: boolean = false;
  public config: CalendarConfig;
  public keys = Object.keys;
  public perPageControl: FormControl = new FormControl(10);
  public frozenColumns: TableColumn[];
  private subscription: Subscription = new Subscription();
  private originalColumns: TableColumn[];
  private defaultTexts: TableText = {
    filtersLabel: 'Filters',
    itemsSelectedMessage: '{0} items selected',
    resetSortingLabel: 'Reset sorting',
    descendingLabel: 'Descending',
    ascendingLabel: 'Ascending',
    sortButtonLabel: 'Sort table',
    resetFilterButtonLabel: 'Clear filters',
    noResults: 'No results found',
    footerPaginationText: 'showing {0} of {1} entries',
    filtersEditHeader: 'Edit filters',
    filtersEditCloseBtn: 'Close',
    filtersEditSelectItemsMessage: 'Select the filters you want to use',
    applyFilterMessage: 'Apply filter',
  };
  private defaultFiltersStyle = {
    tableFiltersStyleClass: 'row',
    filterWrapperStyleClass: 'col-12 col-sm-6 col-md-4 col-xl-3',
    filterStyleClass: 'cap-rounded',
  };

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private calendarService: CapturumCalendarService,
  ) {
    this.subscription = this.calendarService.getConfig()
      .subscribe(config => this.config = config);
  }

  private _texts: TableText = this.defaultTexts;

  get texts(): Partial<TableText> {
    return this._texts;
  }

  @Input()
  set texts(value: Partial<TableText>) {
    this._texts = { ...this.defaultTexts, ...value };
  }

  private _filtersStyle: Partial<TableFilterStyles> = this.defaultFiltersStyle;

  get filtersStyle(): Partial<TableFilterStyles> {
    return this._filtersStyle;
  }

  @Input()
  set filtersStyle(value: Partial<TableFilterStyles>) {
    this._filtersStyle = { ...this.defaultFiltersStyle, ...value };
  }

  private _columns: TableColumn[];

  // Columns
  get columns(): TableColumn[] {
    return this._columns;
  }

  /**
   * The definition of the columns to be used by the table
   */
  @Input()
  set columns(value: TableColumn[]) {
    if (value) {
      this.setSortableColumns(value);
      this.frozenColumns = value.filter(column => !!column.frozen);
    }

    this._columns = value;
  }

  private _defaultRowsPerPage: number;

  get defaultRowsPerPage(): number {
    return this._defaultRowsPerPage;
  }

  @Input()
  set defaultRowsPerPage(value: number) {
    if (value) {
      this.perPageControl.setValue(value);
    }

    this._defaultRowsPerPage = value;
  }

  public onSearchEmitted(searchStr: string): void {
    if (this.lazyLoading) {
      this.resetPagination();
      this.setGlobalFilter(searchStr);

      if (!searchStr && this.usePrimeFiltering) {
        this.checkTableState();
      }
    }

    this.onSearch.emit(searchStr);
  }

  public ngOnInit(): void {
    this.setDefaultColumns('defaultColumns', this._columns);
    this.originalColumns = this._columns && JSON.parse(JSON.stringify(this._columns));
    this.activeFilters = this.getDefaultTableFilters();

    if (this.perPageOptions === undefined) {
      this.perPageOptions = [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
      ];
    }

    const stateRows = this.getStateItem<number>('page_rows');
    if (stateRows) {
      this.perPageControl.setValue(stateRows);
      this.paginator.rows = stateRows;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public createLazyLoadEvent(): LazyLoadEvent {
    const lazyLoadEvent: LazyLoadEvent = {};
    const stateFilters = this.getStateItem<TableFilter>('filters');
    const globalFilters = this.getGlobalState<TableFilter>();
    let filters: TableFilter;

    if (stateFilters) {
      filters = stateFilters;
    }

    this.activeFilters = { ...this.getDefaultTableFilters(), ...filters };

    lazyLoadEvent.filters = filters;
    lazyLoadEvent.globalFilter = globalFilters?.global?.value;

    const stateSort = this.getStateItem<TableSorting[]>('sort');
    if (stateSort) {
      if (this.sortMode === 'multiple' && stateSort.length) {
        this.sortOrderFields = this.sortOrderFields.map((sortOrderField) => ({
          ...sortOrderField,
          ...stateSort.find((stateSortField) => stateSortField.field === sortOrderField.field),
        })).sort((a, b) => {
          return stateSort.findIndex(item => item.field === a.field) - stateSort.findIndex(item => item.field === b.field);
        });

        lazyLoadEvent.multiSortMeta = this.sortOrderFields
          .filter((column) => column.sortDirection)
          .map((column) => {
            return { field: column.field, order: column.sortDirection === 'asc' ? 1 : -1 } as SortMeta;
          });
      } else {
        lazyLoadEvent.sortField = (stateSort as any).field;
        lazyLoadEvent.sortOrder = (stateSort as any).order;
      }
    }

    const stateRows = this.getStateItem<number>('page_rows');
    if (stateRows) {
      lazyLoadEvent.rows = stateRows;
    }

    const firstRows = this.getStateItem<number>('first_rows');
    lazyLoadEvent.first = firstRows || 0;

    const stateColumns = this.getStateItem<TableColumn[]>('columns');
    if (stateColumns) {
      this.columns = this.columns.map((item) => this.getDefaultColumnProperties(item, stateColumns));

      const invisibleColumns = this.columns.filter((column) => !column.visible).map((column) => column.sortField);

      this.sortOrderFields = this.sortOrderFields.filter((field) => {
        return !invisibleColumns.includes(field.field);
      });
    }

    return lazyLoadEvent;
  }

  public checkTableState(): void {
    setTimeout(() => {
      const lazyLoadEvent = this.createLazyLoadEvent();

      this.loadTableData(lazyLoadEvent);
    });
  }

  public ngAfterContentInit(): void {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'after-filters':
          this.afterFiltersTemplate = item.template;
          break;
        case 'right-filters':
          this.rightFiltersTemplate = item.template;
          break;
        case 'expansion':
          this.expansionTemplate = item.template;
          break;
      }
    });
  }

  public displayFilterSettings(): void {
    const defaultColumns = this.getStateItem<TableColumn[]>('defaultColumns');
    let items = this.columns.filter(item => this.originalColumns.find(col => col.field === item.field).filterable);

    if (defaultColumns) {
      items = this.columns.filter(item => defaultColumns.find(col => col.field === item.field).filterable);
    }

    const dialogRef = this.dialogService.open(TableFiltersSettingsComponent, {
      data: {
        items,
        texts: this.texts,
      },
      showHeader: false,
      styleClass: 'table-filters-dialog',
    });

    dialogRef.onClose.pipe(take(1)).subscribe(columns => {
      this.columns = this.columns.map(item => this.getDefaultColumnProperties(item, columns));
      this.setDefaultColumns('columns', this.columns);
      this.manageFilterValues();

      if (this.lazyLoading) {
        const nonFilterableColumns = columns.filter((column) => !column.filterable).map((column) => column.filterField);

        for (const nonFilterableColumn of nonFilterableColumns) {
          delete this.lazyLoadEvent.filters[nonFilterableColumn];
          delete this.activeFilters[nonFilterableColumn];
        }

        this.loadTableData(this.lazyLoadEvent);
      } else {
        for (const tableColumn of columns.filter((column) => !column.filterable)) {
          this.primeTableRef.filter(null, tableColumn.filterField, null);
        }
      }

      this.onFilterVisibleChange.emit(this.columns);
    });
  }

  /**
   * Load table data
   */
  public loadTableData(event: LazyLoadEvent): void {
    if (this.stateKey && !this.stateInitialized) {
      this.stateInitialized = true;
      this.checkTableState();

      return;
    }

    let eventWithFilters = JSON.parse(JSON.stringify(event));

    // Prevent loss of filters after switching page
    if (this.stateKey && !this.ignoreTableStateManagement) {
      const lazyLoadEvent = this.createLazyLoadEvent();

      eventWithFilters = {
        ...eventWithFilters,
        filters: { ...lazyLoadEvent.filters, ...event.filters },
        sortField: event.sortField ? event.sortField : lazyLoadEvent.sortField,
        sortOrder: event.sortOrder ? event.sortOrder : lazyLoadEvent.sortOrder,
      };
    }

    this.lazyLoadEvent = eventWithFilters;
    this.onLazyLoad.emit(eventWithFilters);
  }

  /**
   * Get style class for table
   */
  public getTableStyleClass(selectedValue: any[]): string {
    const tableClasses = ['list-page-table'];

    if (selectedValue && selectedValue.length > 0) {
      tableClasses.push('rows-selected');
    }

    return tableClasses.join(' ');
  }

  /**
   * Get full colspan for 'no results' message
   */
  public getFullColspan(): number {
    let colspan = this._columns.filter(item => item.visible && !item.styleClass.includes('d-none')).length;

    if (this.actions) {
      colspan++;
    }

    if (this.selectable) {
      colspan++;
    }

    if (this.manageableColumns) {
      colspan++;
    }

    if (!!this.rowActions) {
      colspan++;
    }

    return colspan;
  }

  /**
   * When a row is checked in the table
   *
   * @return void
   */
  public onCheckboxClick(rowData: any): void {
    // This event passes all selected rows
    this.onCheckboxToggle.emit(this.selectedValue);

    // This event passes the selected row
    this.onRowToggle.emit(rowData);
  }

  /**
   * Change number of rows shown in table
   */
  public onPerPageChange(): void {
    this.lazyLoadEvent.rows = this.perPageControl.value;

    this.setStateItem('page_rows', this.perPageControl.value);

    this.onNumRowsChange.emit(this.lazyLoadEvent);
    this.onLazyLoad.emit(this.lazyLoadEvent);
  }

  public onSortChange(columns: TableSorting[]): void {
    this.primeTableRef.multiSortMeta = columns
      .filter(column => column.sortDirection)
      .map(sort => {
        return { field: sort.field, order: sort.sortDirection === 'asc' ? 1 : -1 };
      });

    if (!columns.hasOwnProperty('multisortmeta')) {
      this.setStateItem(
        'sort',
        columns.map(column => ({
          field: column.field,
          sortDirection: column.sortDirection,
          sortOrder: column.sortOrder,
        })),
      );

      this.primeTableRef.sortMultiple();

      this.onSort.emit(columns);
    }
  }

  public onTableFilter(filters: { [key: string]: { matchMode: FilterMatchMode; value: any } }): void {
    const stateFilters = { ...filters };
    for (const key in stateFilters) {
      if (stateFilters.hasOwnProperty(key) && !stateFilters[key].value) {
        delete stateFilters[key];
      }
    }

    this.setStateItem('filters', stateFilters);
    this.resetPagination();

    if (this.usePrimeFiltering) {
      for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
          this.primeTableRef.filter(filters[key].value, key, filters[key].matchMode);
        }
      }
    } else {
      this.onFilter.emit(filters);
    }
  }

  public resetPagination(): void {
    this.setStateItem('first_rows', 0);
  }

  public onTableSort(event: any): void {
    if (!event.hasOwnProperty('multisortmeta')) {
      this.setStateItem('sort', event);
      this.onSort.emit(event);
    }
  }

  public onTablePage(event: any): void {
    this.setStateItem('first_rows', event.first);
    this.onPage.emit(event);
  }

  public toggleFilters(): void {
    if (this.collapsibleFilters) {
      this.hideFilters = !this.hideFilters;
    }
  }

  public setSortableColumns(columns: TableColumn[]): void {
    this.sortOrderFields = columns
      .filter(column => column.sortable)
      .map(column => {
        return { header: column.header, field: column.sortField || column.field, sortDirection: null };
      });
  }

  public showResetFilters(): boolean {
    return this.resettableFilters && !!this.activeFilters && !!Object.keys(this.activeFilters).length;
  }

  public resetFilters(defaultFilters?: TableFilter): void {
    this.activeFilters = {
      ...defaultFilters,
    };
    this.setStateItem('filters', this.activeFilters);
    this.setStateItem('first_rows', 0);

    if (this.searchable && this.tableFiltersRef) {
      const searchControl: FormControl = this.tableFiltersRef['searchControl'];

      if (!!searchControl?.value) {
        this.setGlobalFilter(null);

        if (this.primeTableRef?.filters?.global) {
          this.primeTableRef.filters.global = null;
        }

        searchControl.reset('');
      }
    }

    this.onFilter.emit(this.activeFilters);

    if (this.usePrimeFiltering) {
      this.primeTableRef.filters = this.activeFilters;

      this.loadTableData(this.primeTableRef.createLazyLoadMetadata());
    }
  }

  public trackByFn(index: number, item: any): number {
    return item.id;
  }

  public setDefaultColumns(key: string, columns: TableColumn[]): void {
    const defaultItems = [];

    if (columns && columns.length) {
      for (const item of columns) {
        defaultItems.push({
          field: item.field,
          filterable: item.filterable,
          visible: item.visible,
          sortable: item.sortable,
          manageable: item.manageable,
        } as TableColumn);

        this.columns = columns;
      }

      if (this.lazyLoadEvent) {
        let shouldReload = false;
        const invisibleColumns = columns
          .filter((column) => !column.visible)
          .map((column) => column.sortField);

        if (this.lazyLoadEvent && this.lazyLoadEvent.multiSortMeta && this.sortMode === 'multiple') {
          const sortFields = this.lazyLoadEvent.multiSortMeta.filter((sort) => {
            if (!invisibleColumns.includes(sort.field)) {
              return true;
            } else {
              shouldReload = true;

              return false;
            }
          });

          this.sortOrderFields = this.sortOrderFields.filter((field) => {
            return !invisibleColumns.includes(field.field);
          });

          this.setStateItem(
            'sort',
            this.sortOrderFields.map(column => ({
              field: column.field,
              sortDirection: column.sortDirection,
              sortOrder: column.sortOrder,
            })));

          this.lazyLoadEvent = { ...this.lazyLoadEvent, multiSortMeta: sortFields };
        } else {
          if (invisibleColumns.includes(this.lazyLoadEvent.sortField)) {
            shouldReload = true;
            this.lazyLoadEvent.sortField = null;
          }
        }

        if (shouldReload) {
          this.loadTableData(this.lazyLoadEvent);
        }

        this.onColumnVisibleChange.emit(this.lazyLoadEvent);
      }

      this.sortOrderFields = this.columns
        .filter(column => column.sortable && column.visible)
        .map(column => {
          return { header: column.header, field: column.sortField || column.field, sortDirection: null };
        });

      if (this.stateKey) {
        this.setStateItem(key, defaultItems);
      }
    }
  }

  public implementMakeRowsSameHeight(wrapperSelector?: string, frozenSelector?: string, unfrozenSelector?: string): void {
    // to prevent some class name changes after update of prime ng
    wrapperSelector = wrapperSelector || '.p-datatable-scrollable-wrapper';
    frozenSelector = frozenSelector || '.p-datatable-frozen-view tr';
    unfrozenSelector = unfrozenSelector || '.p-datatable-unfrozen-view tr';

    if (this.makeRowsTheSameHeight && this.frozenColumns) {
      setTimeout(() => {
        const wrapper = document.querySelector(wrapperSelector);

        if (wrapper) {
          const frozenRows = wrapper.querySelectorAll(frozenSelector);
          const unfrozenRows = wrapper.querySelectorAll(unfrozenSelector);

          for (let index = 0; index < frozenRows.length; index++) {
            const frozenHeight = frozenRows[index].clientHeight;
            const unFrozenHeight = unfrozenRows[index].clientHeight;
            const height = Math.round(Math.max(frozenHeight, unFrozenHeight));

            frozenRows[index]['style'].height = `${height}px`;
            unfrozenRows[index]['style'].height = `${height}px`;
          }
        }
      });
    }
  }

  private setGlobalFilter(value: string = ''): void {
    if (this.stateKey && this.searchable) {
      const localStorageItem = JSON.parse(localStorage.getItem(this.stateKey));

      if (localStorageItem?.filters?.global) {
        delete localStorageItem.filters.global;

        localStorage.setItem(this.stateKey, JSON.stringify(localStorageItem));
      }

      // Trigger "filterGlobal" to update global filter in PrimeNG component
      if (this.usePrimeFiltering) {
        this.primeTableRef.filterGlobal(value, FilterMatchMode.LIKE);
      }
    }
  }

  private setStateItem(stateItemKey: string, item: any): void {
    if (!this.stateKey) {
      return;
    }

    localStorage.setItem(`base_list_state_${this.stateKey}_${stateItemKey}`, JSON.stringify(item));
  }

  private getStateItem<T>(stateItemKey: string): T {
    if (!this.stateKey) {
      return undefined;
    }

    try {
      const localStorageItem = JSON.parse(localStorage.getItem(`base_list_state_${this.stateKey}_${stateItemKey}`));

      if (stateItemKey === 'filters') {
        Object.keys(localStorageItem).forEach(field => {
          const filter = localStorageItem[field];

          // Date object is saved as string in local storage, convert back to Date object here
          if (filter.value && Array.isArray(filter.value)) {
            filter.value = filter.value.map(filterValue => {
              if ((typeof filterValue === 'string') && isValid(filterValue.trim())) {
                filterValue = new Date(filterValue.trim());
              }

              return filterValue;
            });
          }

          localStorageItem[field] = filter;
        });
      }

      return localStorageItem as T;
    } catch {
      return undefined;
    }
  }

  private getGlobalState<T>(): T {
    if (this.stateKey) {
      const localStorageItem = JSON.parse(localStorage.getItem(this.stateKey));

      if (localStorageItem?.filters?.global) {
        return localStorageItem.filters as T;
      }
    }

    return undefined;
  }

  private manageFilterValues(): void {
    const stateFilters = this.getStateItem<TableFilter>('filters');

    if (stateFilters) {
      this.columns.filter(item => !item.filterable).forEach(column => {
        delete stateFilters[column.field];
      });
    }


    this.activeFilters = { ...this.getDefaultTableFilters(), ...stateFilters } || {};
    this.setStateItem('filters', stateFilters);
  }

  private getDefaultColumnProperties(column: TableColumn, defaultColumns: TableColumn[]): TableColumn {
    const defaultColumn = defaultColumns.find(col => col.field === column.field);

    return {
      ...column,
      visible: column.visible === false ? false : (defaultColumn ? defaultColumn.visible : column.visible),
      filterable: column.filterable === false ? false : (defaultColumn ? defaultColumn.filterable : column.filterable),
    };
  }

  private getDefaultTableFilters(): TableFilter {
    const filters: TableFilter = {};

    if (this.filters && Array.isArray(this.filters)) {
      for (const filter of this.filters) {
        if (filter.field) {
          filters[filter.field] = {
            matchMode: filter.operator as FilterMatchMode,
            value: filter.value,
          };
        }
      }

      return filters;
    }

    return {};
  }

}
