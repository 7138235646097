import { AfterViewInit, Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, NgControl, NgModel } from '@angular/forms';
import { ValidatorService, ValueAccessorBase } from '@capturum/ui/api';

@Component({
  selector: 'cap-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CapturumChipsComponent,
      multi: true
    }]
})
export class CapturumChipsComponent extends ValueAccessorBase<string[]> implements AfterViewInit {
  @ViewChild(NgModel, { static: true }) public model: NgModel;
  /**
   * Inline style of the element
   */
  @Input() public style: any;
  /**
   * Style class of the element
   */
  @Input() public styleClass: string = '';
  /**
   * When present, it specifies that the element should be disabled
   */
  @Input() public disabled: boolean = undefined;
  /**
   * Name of the property to display on a chip
   */
  @Input() public field: string;
  /**
   * Advisory information to display on input
   */
  @Input() public placeholder: string;
  /**
   * The custom validation property name
   */
  @Input() public label: string;
  /**
   * Maximum number of entries allowed
   */
  @Input() public max: number;
  /**
   * Index of the element in tabbing order
   */
  @Input() public tabindex: number;
  /**
   * Identifier of the focus input to match a label defined for the component
   */
  @Input() public inputId: string;
  /**
   * Whether to allow duplicate values or not
   */
  @Input() public allowDuplicate = true;
  /**
   * Inline style of the input field
   */
  @Input() public inputStyle: any;
  /**
   * Style class of the input field
   */
  @Input() public inputStyleClass: any;
  /**
   * Whether to add an item on tab key press
   */
  @Input() public addOnTab: boolean;
  /**
   * Whether to add an item when the input loses focus
   */
  @Input() public addOnBlur: boolean;
  /**
   * Callback to invoke when a value is added
   */
  @Output() public add: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when a value is removed
   */
  @Output() public remove: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when a input is focused
   */
  @Output() public focus: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when a input loses focus
   */
  @Output() public blur: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when a chip is clicked
   */
  @Output() public chipClick: EventEmitter<any> = new EventEmitter();

  public control: FormControl<string>;

  constructor(private injector: Injector,
              private validatorService: ValidatorService) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
