<p-selectButton
  [optionLabel]="optionLabel"
  [optionValue]="optionValue"
  [dataKey]="dataKey"
  [styleClass]="'cap-select-button ' + styleClass"
  [style]="style"
  [multiple]="multiple"
  [unselectable]="unselectable"
  [tabindex]="tabindex"
  [(ngModel)]="value"
  [disabled]="disabled"
  [options]="options"
  (onChange)="change.emit($event)"
  (onOptionClick)="optionClick.emit($event)">
</p-selectButton>
