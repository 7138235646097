import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { DynamicFilterComponent, FilterConfigItem } from '../../interfaces/dynamic-filter.interface';
import { CapturumDynamicFilterLayoutComponent } from '../dynamic-filter-layout/dynamic-filter-layout.component';

@Component({
  selector: 'cap-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CapturumDynamicFilterLayoutComponent,
    CapturumCheckboxModule,
    CapturumSharedModule,
    AsyncPipe,
    ReactiveFormsModule,
  ],
})
export class CheckboxFilterComponent implements DynamicFilterComponent<boolean> {
  @Input()
  public filterConfig: FilterConfigItem;

  public updateValue: EventEmitter<boolean> = new EventEmitter<boolean>();
  public formControl = new FormControl();
  public value: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  public setValue(value: boolean): void {
    this.formControl.setValue(value, { emitEvent: false });
    this.cdr.detectChanges();
  }

  public reset(): void {
    this.formControl.setValue(null);
    this.updateValue.emit(null);
  }

  public updateView(): void {
    this.cdr.detectChanges();
  }
}
