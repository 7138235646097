import { Pipe, PipeTransform } from '@angular/core';
import { CalendarConfig } from '@capturum/ui/calendar';

@Pipe({
  name: 'dateFilterTabindex',
  standalone: true,
})
export class DateFilterTabIndexPipe implements PipeTransform {
  public transform(config: CalendarConfig): number {
    if (Object.hasOwn(config, 'showOnFocus')) {
      return config.showOnFocus ? 0 : -1;
    }

    return 0;
  }
}
