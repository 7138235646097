<p-sidebar
  #sidebar
  [(visible)]="visible"
  [position]="position()"
  [styleClass]="styleClass()"
  [modal]="modal()"
  (onHide)="sidebar.destroyModal()"
  [style]="style()"
  [showCloseIcon]="false">
  <div class="cap-sidebar-header">
    {{ title() }}
    <i class="fas fa-times" (click)="closeSidebar($event)"></i>
  </div>

  <div class="cap-sidebar-content" [class.has-footer]="footerTemplate">
    <ng-content></ng-content>
  </div>

  @if (footerTemplate) {
    <div class="cap-sidebar-footer">
      <hr />
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
  }
</p-sidebar>
