import { ComponentType } from '@angular/cdk/portal';
import { EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ListOptions } from '@capturum/api';
import { MapItem } from '@capturum/auth';
import { FilterMatchMode } from '@capturum/ui/api';
import { Observable } from 'rxjs';
import { DynamicFilterType } from '../enums/dynamic-filter-type.enum';

export type FilterConfigItem = DropdownFilterConfigItem | BaseFilterConfigItem | CalendarFilterConfigItem;

export interface DynamicFilterConfig {
  filters: FilterConfigItem[];
  routeFilters?: ActiveFilters[];
  texts?: {
    refresh?: string,
    reset?: string,
    cancel?: string,
    apply?: string,
    filters?: string,
  },
  icons?: {
    refresh?: string,
    reset?: string,
  }
}

export interface DynamicFilterComponent<VALUE_TYPE = any> {
  filterConfig: FilterConfigItem;
  formControl: FormControl;
  updateValue: EventEmitter<VALUE_TYPE>;
  setValue: (value: VALUE_TYPE) => void;
  updateView: () => void;
}

export type BaseFilterConfigItem = {
  type: DynamicFilterType | string;
  label?: string;
  field: string;
  matchMode?: FilterMatchMode | string;
  icon: string;
  placeholder?: string;
  disabled?: Observable<boolean> | boolean;
  showLabel?: boolean;
  transformValue?: (value: any) => any;
  resetFilter?: boolean;
  styleClass?: string;
  debounceTime?: number;
  hidden?: boolean;
  [key: string]: any;
};

export interface DropdownFilterConfigItem extends BaseFilterConfigItem {
  type: DynamicFilterType.dropdown;
  sortAlphabetically?: boolean;
  options: Observable<MapItem[]> | MapItem[];
  dependsOn?: string[];
  preventDisableDependency?: boolean;
  updateListOptions?: (listOptions: ListOptions) => Observable<MapItem[]>;
}

export interface MultiSelectFilterConfigItem extends BaseFilterConfigItem {
  type: DynamicFilterType.multiselect;
  sortAlphabetically?: boolean;
  options: Observable<MapItem[]> | MapItem[];
  maxSelectedLabels?: number;
  selectedItemsLabel?: string;
  dependsOn?: string[];
  updateListOptions?: (listOptions: ListOptions) => Observable<MapItem[]>;
}

export interface CalendarFilterConfigItem extends BaseFilterConfigItem {
  type: DynamicFilterType.date;
  setTimezone?: boolean;
  minDate?: Date | Observable<Date>;
  maxDate?: Date | Observable<Date>;
  showOnFocus?: boolean;
  iconDisplay?: 'button' | 'input';
}

export interface DynamicFilterModuleConfig {
  components?: DynamicComponentMap[];
}

export interface DynamicComponentMap {
  name: string;
  component: ComponentType<DynamicFilterComponent>;
}

export interface ActiveFilters {
  field: string;
  value: any;
  matchMode: FilterMatchMode | string;
}

export interface ActiveTokenFilter extends ActiveFilters {
  label: string;
}

export interface RangeFilterConfigItem extends BaseFilterConfigItem {
  options: {
    mask?: string;
    toLabel?: string;
    fromLabel?: string;
    emptyMessage?: string;
    confirmButtonText?: string;
  };
}
