import { AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgControl, NgModel } from '@angular/forms';
import { ValidatorService, ValueAccessorBase } from '@capturum/ui/api';

interface ColorPickerFormGroup {
  colorPickerInput: FormControl<string>;
}

@Component({
  selector: 'cap-color-picker',
  templateUrl: './color-picker.component.html',
  providers: [ValueAccessorBase.getProviderConfig(CapturumColorPickerComponent)]
})
export class CapturumColorPickerComponent extends ValueAccessorBase<string> implements AfterViewInit, OnInit {
  @ViewChild(NgModel, { static: true }) public model: NgModel;
  @Input() public style: any;
  @Input() public styleClass: string;
  @Input() public inline: boolean;
  @Input() public format = 'hex';
  @Input() public appendTo: string;
  @Input() public disabled: boolean = undefined;
  @Input() public tabindex: string;
  @Input() public label: string = '';
  @Input() public placeholder: string = '';
  @Input() public inputId: string;
  @Input() public autoZIndex = true;
  @Input() public baseZIndex = 0;
  @Input() public showTransitionOptions = '225ms ease-out';
  @Input() public hideTransitionOptions = '195ms ease-in';
  @Input() public displayInput: boolean = true;
  /**
   * Specifies if the tooltip is present
   */
  @Input() public hasTooltip: boolean;
  @Output() public changeColor: EventEmitter<any> = new EventEmitter();

  public control: FormControl;
  public form: FormGroup<ColorPickerFormGroup>;

  constructor(
    private injector: Injector,
    private validatorService: ValidatorService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      colorPickerInput: [''],
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }

  public pickColor({ target }: any): void {
    if (this.isValidColor(target.value)) {
      const color = this.isHex(target.value, 3) ? this.toHexColor(target.value) : target.value;

      this.value = color;
      this.form.controls.colorPickerInput.setValue(color);
      this.changeColor.emit(color);
    } else {
      this.form.controls.colorPickerInput.setValue(this.value);
    }
  }

  private isValidColor(color: string): boolean {
    return this.isHex(color, 3) || this.isHex(color, 6);
  }

  private isHex(color: string, length: number = 6): boolean {
    const regExp = new RegExp(`^#([0-9A-F]){${length}}$`, 'i');

    return regExp.test(color);
  }

  private toHexColor(color: string): string {
    return '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
  }
}
