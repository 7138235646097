import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { CapturumColumnTemplatePipe, ItemExpression, ThemeService } from '@capturum/ui/api';
import { Observable } from 'rxjs';
import { InfoTableColumnType } from '../../base/info-table-column-type.enum';
import { InfoTableColumn } from '../../base/info-table-column.model';
import { InfoTableConfig } from '../../base/info-table-config.model';
import { ColumnsByTypePipe } from '../../pipes/columns-by-type.pipe';
import { InfoTableConfigService } from '../../services/info-table-config.service';

@Component({
  selector: 'cap-info-table-row',
  templateUrl: './info-table-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColumnsByTypePipe, CapturumColumnTemplatePipe],
})
export class InfoTableRowComponent {
  @Input() public columns: InfoTableColumn[];
  @Input() public clickable: boolean;
  @Input() public cardsView: boolean;
  @Input() public hasFrozenColumns: boolean;
  @Input() public item: any;
  @Input() public rowIndex: number;
  @Input() public selectable: boolean;
  @Input() public reorderableRows: boolean;
  @Input() public reorderableRowIcon: string;
  @Input() public templates: QueryList<any>;
  @Input() public selectedValues: any[] = [];
  @Input() public editableRows: boolean;
  @Input() public editing: boolean;
  @Input() public loading: boolean;
  @Input() public editTable: boolean;
  @Input() public isEdit: boolean;
  @Input() public dataKey: string;
  @Input() public styleClassExpression: ItemExpression;
  @Output() public onRowClick = new EventEmitter<any>();
  @Output() public onRowCtrlClick = new EventEmitter<any>();
  @Output() public onColumnClick = new EventEmitter<{ column: string; row: any }>();
  @Output() public onRowEditClick = new EventEmitter<any>();
  @Output() public onRowEditSaveClick = new EventEmitter<any>();
  @Output() public onRowEditCancelClick = new EventEmitter<{ item: any; index: number }>();

  public readonly config$: Observable<InfoTableConfig>;
  public DataTableType: typeof InfoTableColumnType = InfoTableColumnType;
  public pageWidth: number;
  public selectedRowClass = 'cap-table-row-clicked';

  constructor(
    private readonly infoTableConfigService: InfoTableConfigService,
    private readonly columnsByTypePipe: ColumnsByTypePipe,
    private readonly columnTemplatePipe: CapturumColumnTemplatePipe,
  ) {
    this.config$ = this.infoTableConfigService.getConfig();
    this.setPageWidth();
  }

  /**
   * When an element in the table is clicked the user gets routed to another page
   */
  public onElementClick(event: any, rowData: any, column: InfoTableColumn): void {
    if (!this.clickable) {
      return;
    } else if (column.disableRowClick) {
      this.onColumnClick.emit({ column: column.field, row: rowData });

      return;
    }

    // Prevent row click for selection td
    if (this.selectable) {
      const $td = event.target.closest('td');

      if ($td.classList.contains('selectable-wrapper')) {
        return;
      }
    }

    if (event.metaKey || event.ctrlKey) {
      this.onRowCtrlClick.emit(rowData);
    } else {
      // First remove class of all rows
      const rows = event.target.closest('.cap-info-table table').querySelectorAll('tr.cap-info-table__row');

      for (const row of rows) {
        row.classList.remove(this.selectedRowClass);
      }

      // Add class to selected row
      event.target.closest('tr').classList.add(this.selectedRowClass);

      // Trigger onRowClick event
      this.onRowClick.emit(rowData);
    }
  }

  public hasCardHeaderContent(breakpoint: number): boolean {
    const existActionType = this.columnsByTypePipe.transform(this.columns, InfoTableColumnType.Actions);
    const existCardTemplate = this.columnTemplatePipe.transform(this.templates, 'card-header');

    return this.selectable || ((existActionType || existCardTemplate) && this.pageWidth < breakpoint);
  }

  public onRowEditInit(event: Event, item: any): void {
    event.stopPropagation();
    this.onRowEditClick.emit(item);
  }

  public onRowEditSave(event: Event, item: any): void {
    event.stopPropagation();
    this.onRowEditSaveClick.emit(item);
  }

  public onRowEditCancel(event: Event, item: { item: any; index: number }): void {
    event.stopPropagation();
    this.onRowEditCancelClick.emit(item);
  }

  @HostListener('window:resize', ['$event'])
  private setColumnsSize(): void {
    this.setPageWidth();
  }

  private setPageWidth(): void {
    this.pageWidth = ThemeService.getPageWidth();
  }
}
