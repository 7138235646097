import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { DynamicFilterComponent, MultiSelectFilterConfigItem } from '../../interfaces/dynamic-filter.interface';
import { CapturumDynamicFilterLayoutComponent } from '../dynamic-filter-layout/dynamic-filter-layout.component';

@Component({
  selector: 'cap-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CapturumDynamicFilterLayoutComponent,
    CapturumMultiSelectModule,
    ReactiveFormsModule,
    AsyncPipe,
    CapturumSharedModule,
  ],
})
export class CapturumMultiSelectFilterComponent implements DynamicFilterComponent<string[]> {
  @Input()
  public filterConfig: MultiSelectFilterConfigItem;

  public updateValue: EventEmitter<string[]> = new EventEmitter<string[]>();
  public formControl = new FormControl();

  constructor(private cdr: ChangeDetectorRef) {}

  public setValue(value: string[]): void {
    this.formControl.setValue(value);
    this.cdr.detectChanges();
  }

  public reset(): void {
    this.formControl.reset();
    this.updateValue.emit(this.formControl.value);
  }

  public updateView(): void {
    this.cdr.detectChanges();
  }
}
